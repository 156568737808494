import Viewport from '../core/Viewport';

const inViewport = ($element, threshold) => {
    const boundingRect = $element.get(0).getBoundingClientRect();
    return ((boundingRect.top < (Viewport.height + threshold)) && (boundingRect.bottom > -threshold));
};

export default {
    inViewport
};

