import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Inview from '../lib/Inview';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default (el, props) => {
    const ITEM_SELECTOR = '[data-entrytypemenu-item]';
    const CURRENT_ITEM_SELECTOR = '[data-entrytypemenu-currentitem]';
    const TOGGLE_SELECTOR = '[data-entrytypemenu-toggle]';

    let $element = $(el);
    let isDropdown = false;
    let isOpen = false;
    let timeline = null;
    let buttonsDisabled = props.buttonsDisabled || false;

    let lastScrollTop = null;
    let lastWinWidth = null;

    let elementHeight = 0;
    let pageHeaderHeight = 0;

    let $items = $element.find(ITEM_SELECTOR);
    let $currentItem = $element.find(CURRENT_ITEM_SELECTOR);

    const init = () => {
        $element
            .on('click', TOGGLE_SELECTOR, onToggleClick)
            .on('click', CURRENT_ITEM_SELECTOR, onCurrentItemClick);

        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);

        onScroll();
        onResize();

        Dispatch.on(eventKeys.PAGE_HEADER_RESIZE, onPageHeaderResize, true);
    };

    const destroy = () => {
        $element.off('click');

        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);

        Dispatch.off(eventKeys.PAGE_HEADER_RESIZE, onPageHeaderResize);
        destroyTimeline();
    };

    /* --- Event handlers ---------------------------------------------------------------- */
    function onResize(e) {
        let winWidthChanged = Viewport.width !== lastWinWidth;
        let breakpoint = Viewport.breakpoint.name || 's';
        isDropdown = ['sm', 's', 'sp'].indexOf(breakpoint) > -1;
        if (!timeline || (!isDropdown && winWidthChanged)) {
            createTimeline();
        }
        lastWinWidth = Viewport.width;
        elementHeight = $element.outerHeight();
    }

    function onPageHeaderResize(key, data) {
        pageHeaderHeight = data.height;
    }

    function onScroll(e) {
        let scrollTop = Viewport.scrollTop;

        if (lastScrollTop) {
            lastScrollTop = scrollTop;
        }

        if (!isDropdown && (Math.abs(lastScrollTop - scrollTop) > (elementHeight + 20))) {
            closeMenu();
        }
    }

    function onBreakpoint(e) {
        // When the breakpoint changes, delete the timeline and create it afresh
        let breakpoint = Viewport.breakpoint.name || 's';
        let currentlySmall = isDropdown;
        isDropdown = ['sm', 's', 'sp'].indexOf(breakpoint) > -1;
        if (currentlySmall !== isDropdown) {
            createTimeline();
        }
    }

    function onToggleClick(e) {
        if (!isDropdown) {
            e.preventDefault();
            toggleMenu();
        }
    }

    function onCurrentItemClick(e) {
        e.preventDefault();
        toggleMenu();
    }

    function onFocus(e) {
        openMenu();
    }

    /* --- Private methods --------------------------------------------------------------- */
    function toggleMenu() {
        if (!isOpen) {
            openMenu();
        } else {
            closeMenu();
        }
    }

    function openMenu() {
        if (isOpen) {
            return false;
        }
        isOpen = true;
        enableElements([isDropdown ? $items.not('.-current') : $items]);
        $element.addClass('-open');
        if (timeline) {
            timeline.play();
        }
    }

    function closeMenu() {
        if (!isOpen) {
            return false;
        }
        isOpen = false;
        disableElements([$items]);
        $element.removeClass('-open');
        if (timeline) {
            timeline.reverse();
        }
    }

    function createTimeline() {
        destroyTimeline();
        if (isDropdown) {
            createDropdownTimeline();
        } else {
            createButtonsTimeline();
        }
    }

    function destroyTimeline() {
        if (!timeline) {
            return false;
        }
        timeline.pause(timeline.duration);
        timeline.kill();
        timeline = null;
        isOpen = false;
        $element.removeClass('-open');
        $items
            .attr('style', '')
            .children()
            .attr('style', '');
        $currentItem
            .attr('style', '')
            .children()
            .attr('style', '');
        enableElements([$currentItem, $items]);
    }

    function createDropdownTimeline() {

        disableElements([$items]);

        gsap.set($items, {
            position: 'relative'
        });

        timeline = gsap.timeline({
            paused: true,
            onComplete: function() {
                // If the top of the dropdown is not in the viewport, scroll
                let $firstItem = $element.find(CURRENT_ITEM_SELECTOR);
                if (!Inview.inViewport($firstItem, -50)) {
                    gsap.to(window, {
                        duration: 0.5, scrollTo: {
                            y: $firstItem.offset().top - (pageHeaderHeight + 20)
                        },
                        ease: 'cubic.inOut'
                    });
                }
            }
        });

        let $item,
            itemPos;

        $items.each(function() {
            $item = $(this);
            itemPos = $item.position().top;
            timeline.fromTo($item, 0.5, {
                x: 0,
                y: 0,
                marginTop: -itemPos
            }, {
                x: 0,
                y: 0,
                marginTop: 0,
                ease: 'cubic.inOut'
            }, 'item');
        });

    }

    function createButtonsTimeline() {

        if (buttonsDisabled) {
            return false;
        }

        disableElements([$currentItem, $items]);

        $items.css({
            pointerEvents: 'auto',
            opacity: 1
        });

        timeline = gsap.timeline({
            paused: true
        });

        let items = $items.get();

        for (let i = 0; i < items.length; ++i) {

            let $item = $(items[i]);

            timeline
                .fromTo($item, {
                    duration: 0.3,
                    x: -($item.outerWidth() + $item.position().left + 10),
                    y: 0
                }, {
                    x: 0,
                    y: 0,
                    ease: 'cubic.out',
                    delay: 0.05
                }, 'item')
                .fromTo($item, {
                    duration: 0.2,
                    opacity: 0
                }, {
                    opacity: 1,
                    ease: 'sine.in'
                }, 'item+=0.05');

        }

    }

    function enableElements(elements) {
        for (let i = 0; i < elements.length; ++i) {
            elements[i].find('a').attr({
                'aria-hidden': null,
                tabIndex: null
            });
        }
    }

    function disableElements(elements) {
        for (let i = 0; i < elements.length; ++i) {
            elements[i].find('a').attr({
                'aria-hidden': true,
                tabIndex: -1
            });
        }
    }

    return {
        init, destroy
    };
};
