import $ from 'jquery';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../../lib/events';
import LazySizes from 'lazysizes';

export default (el, props) => {
    const ARROW_SELECTOR = '[data-eventteaser-arrow]';
    const ARROW_LINE_SELECTOR = '[data-eventteaser-arrow-line]';


    let $element = $(el);
    let $arrow = $element.find(ARROW_SELECTOR);
    let $arrowLine = $element.find(ARROW_LINE_SELECTOR);
    let timeline;

    let isClickable = props.isClickable;


    const init = () => {
        if (!isClickable) {
            return false;
        }
        $element
            .on('dragstart', 'img', function(e) {
                e.preventDefault();
            })
            .on('mouseenter', onMouseEnter)
            .on('mouseleave', onMouseLeave);
    };

    const destroy = () => {
        $element.off('dragstart');
        if (timeline) {
            timeline.kill();
            timeline = null;
        }
    };

    /* --- Private methods --------------------------------------------------------------- */
    function onMouseEnter(e) {
        if (timeline) {
            timeline.kill();
        }
        timeline = gsap.timeline()
            .fromTo($arrow, {
                duration: 0.1, opacity: 0
            }, {
                opacity: 1
            }, 'arrow')
            .fromTo($arrowLine, {
                duration: 0.5, scaleX: 2, transformOrigin: 'right center'
            }, {
                delay: 0.1, scaleX: 1, transformOrigin: 'right center', ease: 'cubic.out'
            }, 'arrow')
            .fromTo($arrow, {
                duration: 0.5, xPercent: -250
            }, {
                xPercent: 0, ease: 'cubic.out'
            }, 'arrow');
    }

    function onMouseLeave(e) {
        if (timeline) {
            timeline.kill();
        }
        timeline = gsap.timeline({
            onComplete: function() {
                gsap.set($arrow, {
                    opacity: 0
                });
            }
        }).to($arrowLine, {
                duration: 0.25, scaleX: 0.5, transformOrigin: 'right center', ease: 'cubic.in'
            }, 'arrow')
            .to($arrow, {
                duration: 0.25, xPercent: 250, ease: 'cubic.in'
            }, 'arrow');
    }

    return {
        init, destroy
    };
};
