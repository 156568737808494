import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import Flickity from 'flickity';
import Browser from '../lib/Browser';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const CLOSE_BTN_SELECTOR = '[data-modalslideshow-closebtn]';
    const ITEMS_CONTAINER_SELECTOR = '[data-modalslideshow-items]';
    const COUNTER_SELECTOR = '[data-modalslideshow-counter]';
    const CAPTION_SELECTOR = '[data-modalslideshow-caption]';

    const MODAL_BG_SELECTOR = '[data-modalslideshow-bg]';
    const MODAL_HEADING_SELECTOR = '[data-modalslideshow-heading]';
    const MODAL_FOOTER_SELECTOR = '[data-modalslideshow-footer]';

    const POINTER_SELECTOR = '[data-modalslideshow-pointer]';

    let $element = $(el);
    let modalMarkup = props.modal || null;
    let id = props.id || null;

    let isModalOpen = false;
    let focusOnClose = null;
    let $modal = null;
    let $pointer = null;
    let slideshow = null;
    let transition = null;
    let modalOffset = null;
    let modalWidth = null;
    let isClickable = true;

    const init = () => {
        if (!id || !modalMarkup || !modalMarkup.length) {
            return false;
        }

        // Clicking on a trigger opens up the modal
        $('body').on('click', '[data-modalslideshow-trigger="' + id + '"]', onTriggerClick);
    };

    const destroy = () => {
        $('body').off('click', '[data-modalslideshow-trigger="' + id + '"]', onTriggerClick);
        destroyModal();
    };

    /* --- Event handlers ---------------------------------------------------------------- */
    function onTriggerClick(e) {
        e.preventDefault();
        e.stopPropagation();
        let $target = $(e.currentTarget);
        let index = $target.data('index') || 0;
        openModal(index);
    }

    function onCloseBtnClick(e) {
        e.preventDefault();
        closeModal();
    }

    function onSlideChange() {
        updateCaption();
        updateCounter();
    }

    function onSliderDragStart() {
        isClickable = false;
    }

    function onKeyUp(e) {
        if (e.keyCode === 27) {
            closeModal();
        }
    }

    function onMouseEnter(e) {
        $(e.currentTarget).addClass('-mouseover');
    }

    function onMouseLeave(e) {
        $(e.currentTarget).removeClass('-mouseover');
    }

    function onMouseDown(e) {
        isClickable = true;
    }

    function onMouseMove(e) {
        if (!modalWidth) {
            cacheModalWidth();
        }
        if (!modalOffset) {
            cacheModalOffset();
        }
        var x = e.pageX - modalOffset.left,
            y = e.pageY - modalOffset.top,
            direction = x > modalWidth / 2 ? 'next' : 'previous';
        gsap.set($pointer, {
            x: x,
            y: y
        });
        if (direction === 'next') {
            $modal[0].classList.remove('-previous');
            $modal[0].classList.add('-next');
        } else {
            $modal[0].classList.remove('-next');
            $modal[0].classList.add('-previous');
        }
    }

    function onItemsClick(e) {
        if (isClickable) {
            if ($modal.hasClass('-next')) {
                next();
            } else {
                prev();
            }
        }
        isClickable = true;
    }

    function onResize(e) {
        if ($modal) {
            cacheModalOffset();
            cacheModalWidth();
        }
    }

    /* --- Private methods --------------------------------------------------------------- */
    function toggleModal() {
        if (isModalOpen) {
            openModal();
        } else {
            closeModal();
        }
    }

    function openModal(initialIndex) {
        if (isModalOpen) {
            return false;
        }

        if (transition) {
            transition.kill();
        }

        initialIndex = initialIndex || 0;

        isModalOpen = true;
        focusOnClose = document.activeElement || null;

        // Append the modal
        $modal = $(modalMarkup);
        let $items = $modal.find(ITEMS_CONTAINER_SELECTOR);

        $('body').append($modal);

        $modal
            .on('dragstart', '*', function(e) {
                e.preventDefault();
            })
            .on('click', CLOSE_BTN_SELECTOR, onCloseBtnClick)
            .on('click', '.flickity-prev-next-button.previous', prev)
            .on('click', '.flickity-prev-next-button.next', next);

        $(document).on('keyup', onKeyUp);

        // Create Flickity slideshow
        slideshow = new Flickity($items.children(':first')[0], {
            wrapAround: true,
            pageDots: false,
            setGallerySize: false,
            initialIndex: initialIndex
        });
        slideshow.on('select', onSlideChange);
        slideshow.on('dragStart', onSliderDragStart);

        updateCounter(initialIndex + 1);
        updateCaption(initialIndex + 1);

        $modal.find('.flickity-prev-next-button.previous').html('').attr('aria-label', 'Forrige');
        $modal.find('.flickity-prev-next-button.next').html('').attr('aria-label', 'Neste');

        $items = $modal.find(ITEMS_CONTAINER_SELECTOR);
        gsap.set($items, { opacity: 0 });

        transition = gsap.timeline({
            paused: true,
            onComplete: function() {
                transition.kill();
                transition = null;
            }
        })
            .fromTo($modal.find(MODAL_BG_SELECTOR), {
                duration: 0.25, opacity: 0
            }, {
                opacity: 1,
                ease: 'cubic.in'
            }, 'in')
            .fromTo($modal.find(MODAL_BG_SELECTOR), {
                duration: 0.5, scale: 0.75,
                transformOrigin: 'center center'
            }, {
                scale: 1,
                transformOrigin: 'center center'
            }, 'in')
            .fromTo($modal.find(MODAL_HEADING_SELECTOR), {
                duration: 0.5, yPercent: -150
            }, {
                yPercent: 0,
                ease: 'cubic.out'
            }, 'heading-=.1')
            .fromTo($modal.find(CLOSE_BTN_SELECTOR), {
                duration: 0.5, yPercent: -150
            }, {
                yPercent: 0,
                ease: 'cubic.out'
            }, 'heading+=.1')
            .fromTo($modal.find(MODAL_FOOTER_SELECTOR), {
                duration: 0.5, yPercent: 100
            }, {
                yPercent: 0,
                ease: 'cubic.out'
            }, 'heading')
            .set($items, {
                opacity: 1
            })
            .add(function() {
                gsap.fromTo($modal.find('.lazyloaded').parent(), {
                    duration: 0.5, opacity: 0
                }, {
                    opacity: 1,
                    ease: 'cubic.in'
                });
            });

        // Set focus
        requestAnimationFrame(function() {
            // todo
            Browser.overrideTabbing($modal, $items.children(':first'));
            Dispatch.emit(eventKeys.MODAL_OPEN);
            transition.play();
        });

        if ($('html').hasClass('using-mouse')) {
            $pointer = $modal.find(POINTER_SELECTOR);
            $modal
                .on('mouseenter', ITEMS_CONTAINER_SELECTOR, onMouseEnter)
                .on('mouseleave', ITEMS_CONTAINER_SELECTOR, onMouseLeave)
                .on('mousedown', ITEMS_CONTAINER_SELECTOR, onMouseDown)
                .on('click', ITEMS_CONTAINER_SELECTOR, onItemsClick)
                .on('mousemove', onMouseMove);
        }

        Viewport.on('resize', onResize);
        onResize();
    }

    function closeModal() {
        if (!isModalOpen) {
            return false;
        }
        isModalOpen = false;

        if (transition) {
            transition.kill();
        }

        transition = gsap.timeline({
            onComplete: function() {
                destroyModal();
            }
        })
            .to($modal.children(':not(' + MODAL_BG_SELECTOR + ')'), {
                duration: 0.25, opacity: 0,
                ease: 'cubic.in'
            }, 'out')
            .to($modal.find(MODAL_BG_SELECTOR), {
                duration: 0.5, opacity: 0,
                ease: 'cubic.in'
            }, 'out+=0.2');

    }

    function destroyModal() {
        if (!$modal) {
            return false;
        }

        isModalOpen = false;

        $(document).off('keyup', onKeyUp);

        if (transition) {
            transition.kill();
            transition = null;
        }

        // Remove the modal
        $modal
            .remove()
            .off('dragstart click mousemove mouseenter mouseleave');

        $modal = null;
        $pointer = null;

        // Destroy the Flickity instance
        if (slideshow) {
            slideshow.off('select');
            slideshow.off('dragStart');
            slideshow.destroy();
            slideshow = null;
        }

        // Reset focus
        if (focusOnClose) {
            Browser.resetTabbing(focusOnClose);
        }
        focusOnClose = null;

        Dispatch.emit(eventKeys.MODAL_CLOSED);
    }

    function updateCounter(index) {
        if (!$modal || !slideshow) {
            return false;
        }
        $modal.find(COUNTER_SELECTOR).html(index !== undefined ? index : slideshow.selectedIndex + 1);
    }

    function updateCaption(index) {
        if (!$modal || !slideshow) {
            return false;
        }
        let $caption = $modal.find(CAPTION_SELECTOR);
        let caption = $(slideshow.selectedElement).data('caption');
        if (caption === $caption.text()) {
            return;
        }
        gsap.timeline()
            .to($caption, { duration: 0.3, yPercent: 35, ease: 'cubic.in' }, 0)
            .to($caption, { duration: 0.3, opacity: 0 }, 0)
            .add(function() {
                $caption.text($(slideshow.selectedElement).data('caption'));
            })
            .set($caption, { yPercent: 100 }, 0.3)
            .to($caption, { duration: 0.5, yPercent: 0, ease: 'cubic.out' }, 0.3)
            .to($caption, { duration: 0.3, opacity: 1 }, 0.3);
    }

    function cacheModalOffset() {
        if ($modal) {
            modalOffset = $modal.offset();
        }
    }

    function cacheModalWidth() {
        if ($modal) {
            modalWidth = $modal.outerWidth();
        }
    }

    function next(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (slideshow) {
            slideshow.next();
        }
    }

    function prev(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (slideshow) {
            slideshow.previous();
        }
    }

    return {
        init, destroy
    };
};
