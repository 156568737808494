import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import Flickity from 'flickity';
import * as eventKeys from '../lib/events';

export default el => {
    const LIST_SELECTOR = '[data-featureslider-list]';
    const ITEM_SELECTOR = '[data-featureslider-item]';
    const NAV_NEXT_SELECTOR = '[data-featureslider-navbtn-next]';
    const NAV_PREV_SELECTOR = '[data-featureslider-navbtn-prev]';

    let $element = $(el);
    let winW = null;
    let slider = null;
    let currentSlideIndex = null;
    let $list;
    let $items;
    let $navButtons;
    let isBleeding;
    let nextEnabled = null;
    let prevEnabled = null;

    const init = () => {
        $list = $element.find(LIST_SELECTOR);
        $items = $element.find(ITEM_SELECTOR);
        $navButtons = $element.find([NAV_NEXT_SELECTOR, NAV_PREV_SELECTOR].join(','));

        $element
            .on('dragstart', '*', function(e) {
                e.preventDefault();
            })
            .on('mouseenter', ITEM_SELECTOR + ' a', onItemLinkMouseEnter)
            .on('mouseleave', ITEM_SELECTOR + ' a', onItemLinkMouseLeave)
            .on('click', NAV_NEXT_SELECTOR, next)
            .on('click', NAV_PREV_SELECTOR, prev);

        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        $element.off('click');
        $element.off('dragstart');
        $element.off('mouseenter');
        $element.off('mouseleave');
        Viewport.off('resize', onResize);
    };


    function createSlider() {
        destroySlider();

        let itemWidth = $items.outerWidth();
        let totalItemWidth = itemWidth * $items.length;
        let sliderWidth = $element.outerWidth();
        let $item;

        if (totalItemWidth <= sliderWidth) {
            return false;
        }

        $element.addClass('-has-slider');

        $items.each(function() {
            $item = $(this);
            $item.css({
                left: $item.position().left
            });
        });

        $list.css({
            display: 'block', width: '100%', height: $items.outerHeight()
        });

        $items.css({
            position: 'absolute', width: itemWidth
        });

        isBleeding = parseInt(Math.round($element.css('width').replace('px', ''))) > winW;

        let flickityProps = {
            pageDots: false, resize: false, setGallerySize: false, percentPosition: false, contain: true, prevNextButtons: false, groupCells: true, cellAlign: 'left'
        };

        if (isBleeding) {
            flickityProps = $.extend(flickityProps, {
                cellAlign: 'center', freeScroll: true, groupCells: false
            });
        }
        slider = new Flickity($list[0], flickityProps);
        slider.on('select', onSlideSelect);
        slider.on('settle', onSlideSelect);

        let numCells = $items.length;
        if (isBleeding && numCells >= 3) {
            // Center the slider
            currentSlideIndex = Math.round(numCells / 2);
            slider.select(currentSlideIndex, false, true);
        }

        maybeDisableNav();
    }

    function destroySlider() {
        if (!slider) {
            return false;
        }

        slider.off('select');
        slider.off('settle');
        slider.destroy();

        $items.attr('style', '');
        $list.attr('style', '');

        currentSlideIndex = 0;
        nextEnabled = prevEnabled = false;

        $element.removeClass('-next-enabled -prev-enabled -has-slider');
    }

    function maybeDisableNav() {
        if (!slider && (nextEnabled || prevEnabled)) {
            nextEnabled = prevEnabled = false;
            $element.removeClass('-next-enabled -prev-enabled');
            return;
        }

        let numSlides = slider.slides.length, currentSlide = slider.selectedIndex, enableNext = numSlides - 1 > currentSlide, enablePrev = currentSlide > 0;

        if (enableNext !== nextEnabled) {
            nextEnabled = enableNext;
            if (nextEnabled) {
                $element.addClass('-next-enabled');
            } else {
                $element.removeClass('-next-enabled');
            }
        }

        if (enablePrev !== prevEnabled) {
            prevEnabled = enablePrev;
            if (prevEnabled) {
                $element.addClass('-prev-enabled');
            } else {
                $element.removeClass('-prev-enabled');
            }
        }

    }

    function next(e) {
        if (e) {
            e.preventDefault();
        }
        if (slider) {
            slider.next();
        }
    }

    function prev(e) {
        if (e) {
            e.preventDefault();
        }
        if (slider) {
            slider.previous();
        }
    }

    /* --- Event handlers ---------------------------------------------------------------- */
    function onResize(e) {
        if (Viewport.width === winW) {
            return false;
        }
        winW = Viewport.width;
        createSlider();
    }

    function onSlideSelect() {
        maybeDisableNav();
    }

    function onItemLinkMouseEnter(e) {
        $(e.currentTarget).closest(ITEM_SELECTOR).addClass('-mouseover');
    }

    function onItemLinkMouseLeave(e) {
        $(e.currentTarget).closest(ITEM_SELECTOR).removeClass('-mouseover');
    }

    /* --- Private methods --------------------------------------------------------------- */

    return {
        init, destroy
    };
};
