import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const VIEWMENU_SELECTOR = '[data-calendar-viewmenu]';
    const VIEWMENU_TOGGLE_SELECTOR = '[data-calendar-viewtoggle]';
    const VIEWMENU_TOGGLE_DATA_ATTRIBUTE = 'calendar-viewtoggle';

    let $element = $(el);
    let viewCookieName = props.viewCookieName || null;

    const init = () => {
        if (viewCookieName) $element.on('click', VIEWMENU_TOGGLE_SELECTOR, onViewMenuToggleClick);
    };

    const destroy = () => {
        $element.off('click');
    };
    
    /* --- Event listeners ---------------------------------------------------------------- */
    function onViewMenuToggleClick(e) {
        e.preventDefault();
        
        let $toggle = $(e.currentTarget);
        
        if ($toggle.parent().hasClass('is-active')) return false;
        
        let view = $toggle.data(VIEWMENU_TOGGLE_DATA_ATTRIBUTE);
        window.vrsg.setCookie(viewCookieName, view);
        window.location.reload();
    }
    
    return {
        init, destroy
    };
};
