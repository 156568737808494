import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {

    const ITEM_SELECTOR = '[data-lineargrid-item]';
    const ORDERED_SELECTOR = '[data-lineargrid-ordered]';

    let $element = $(el);
    let numItems = 0;
    let rowWidth = 0;

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        Dispatch.on(eventKeys.LOADMORE_LOADED, onLoadMoreLoaded, true);

        setOrder();

        $element.addClass('-js-initialized');
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Dispatch.off(eventKeys.LOADMORE_LOADED, onLoadMoreLoaded);
    };

    /* --- Event listeners ---------------------------------------------------------------- */
    function onBreakpoint(key, data) {
        resetOrder();
        setOrder();
    }

    function onLoadMoreLoaded(key, data) {

        if (data.id !== $element.attr('id')) {
            return false;
        }

        let $content = data.content;
        let $items = $content.find(ITEM_SELECTOR);
        let transition = gsap.timeline({
            paused: true
        });

        appendItems($items);

        Components.parse($items);

        requestAnimationFrame(function() {
            let items = $items.get().sort(function(a, b) {
                return getItemOrder(a) - getItemOrder(b);
            });

            let label;
            for (let i = 0; i < items.length; ++i) {
                label = 'item+=' + (i * 0.15);
                transition
                    .fromTo(items[i], {
                        duration: 0.35, opacity: 0
                    }, {
                        opacity: 1, ease: 'sine.in'
                    }, label)
                    .fromTo(items[i], {
                        duration: 0.5, yPercent: -10
                    }, {
                        yPercent: 0, ease: 'sine.out'
                    }, label);
            }

            transition.play();
        });

    }

    /* --- Private methods --------------------------------------------------------------- */

    function appendItems($items) {
        let scrollTop = Viewport.scrollTop;
        $element.append($items);
        resetOrder();
        setOrder();
        window.scrollTo(0, scrollTop);
    }

    function setOrder() {
        let $itemsToOrder = $element.find(ITEM_SELECTOR);//+':not('+ORDERED_SELECTOR+')');
        let numItemsToPlace = $itemsToOrder.length;

        if (!numItemsToPlace) {
            return false;
        }

        let gridWidth = $element.width();
        let orderedItems = [];

        // Get items and sizes
        let sizes = [];
        let itemWidth;
        let itemsToOrder = $itemsToOrder.get().map(function(element) {
            itemWidth = element.getBoundingClientRect().width;
            if (sizes.indexOf(itemWidth) === -1) {
                sizes.push(itemWidth);
            }

            return {
                width: itemWidth, element: element
            };

        });

        // Figure out the order
        for (let i = 0; i < numItemsToPlace; ++i) {

            for (let j = 0; j < itemsToOrder.length; ++j) {

                let item = itemsToOrder[j];
                let remainderRowWidth = gridWidth - (rowWidth + item.width);

                // Make sure the new row width is not larger than the grid's width
                if (remainderRowWidth < 0) {
                    continue;
                }

                // Make sure there's room for an additional item if need be
                if (remainderRowWidth > 0 && !getValidRemainderWidth(sizes, remainderRowWidth)) {
                    continue;
                }

                orderedItems.push(itemsToOrder.splice(j, 1)[0].element);
                rowWidth += item.width;

                break;

            }

            if (Math.round(rowWidth) >= gridWidth) {
                rowWidth = 0;
            }
        }

        // Shouldn't be any leftovers, but just in case...
        orderedItems = orderedItems.concat(itemsToOrder.map(function(item) {
            return item.element;
        }));

        // Update the items with the new order
        orderedItems.map(function(item, index) {
            item.style.order = index + numItems;
            item.setAttribute(ORDERED_SELECTOR.slice(1, -1), index + numItems);
        });

        numItems += numItemsToPlace;

    }

    function resetOrder() {
        $element.find(ORDERED_SELECTOR).removeAttr(ORDERED_SELECTOR.slice(1, -1));
        numItems = 0;
        rowWidth = 0;
    }

    function getItemOrder(item) {
        return parseInt(item.getAttribute(ORDERED_SELECTOR.slice(1, -1)));
    }

    function getValidRemainderWidth(widths, remainder) {
        return widths.filter(function(width) {
            return remainder === width || Math.round(remainder % width) === 0;
        }).pop();
    }


    return {
        init, destroy
    };
};
