/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const DOM_MODIFIED = 'DOM.MODIFIED';

export const MENU_OPEN = 'GRAFILL.MENU_OPEN';
export const MENU_CLOSE = 'GRAFILL.MENU_CLOSE';
export const MENU_OPENED = 'GRAFILL.MENU_OPENED';
export const MENU_CLOSED = 'GRAFILL.MENU_CLOSED';
export const PAGE_HEADER_RESIZE = 'GRAFILL.PAGE_HEADER_RESIZE';
export const LOADMORE_LOADED = 'GRAFILL.LOADMORE_LOADED';
export const SIDEBAR_CONTENT_SELECTED = 'GRAFILL.SIDEBAR_CONTENT_SELECTED';
export const SIDEBAR_FIXED = 'GRAFILL.SIDEBAR_FIXED';
export const SIDEBAR_ABSOLUTE = 'GRAFILL.SIDEBAR_ABSOLUTE';
export const MODAL_OPEN = 'GRAFILL.MODAL_OPEN';
export const MODAL_CLOSED = 'GRAFILL.MODAL_CLOSED';
export const SCROLLING_LOCKED = 'GRAFILL.SCROLLING_LOCKED';
export const SCROLLING_RELEASED = 'GRAFILL.SCROLLING_RELEASED';
export const VIDEO_MODULE_RESIZED = 'GRAFILL.VIDEO_MODULE_RESIZED';


