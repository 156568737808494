import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const LOADING_CLASSNAME = '-loading';
    const DISABLED_CLASSNAME = '-disabled';
    const BUTTON_SELECTOR = '[data-loadmore-button]';
    const PAGINATION_SELECTOR = '[data-loadmore-pagination]';

    let $element = $(el);
    let isLoading = false;

    let loadmoreId = props.loadmoreId || null;
    let totalPages = props.totalPages || null;
    let currentPage = props.currentPage || 1;
    let nextUrl = props.nextUrl || null;
    let isNativePagination = !!nextUrl;

    const init = () => {
        if (!loadmoreId || !totalPages || currentPage >= totalPages) {
            return false;
        }
        
        $element
            .on('click', BUTTON_SELECTOR, onClick)
            .removeClass(DISABLED_CLASSNAME)
            .find(BUTTON_SELECTOR)
            .removeAttr('tabindex')
            .removeAttr('aria-hidden');
        
        // Hide the fallback pagination links
        $element
            .find(PAGINATION_SELECTOR)
            .find('a')
            .attr('tabindex', '-1')
            .attr('aria-hidden', true);
    };

    const destroy = () => {
        $element.off('click');
    };


    /* --- Event listeners ---------------------------------------------------------------- */
    function onClick(e) {
        e.preventDefault();
        loadMore();
    }

    /* --- Private methods --------------------------------------------------------------- */
    function loadMore() {
        if (isLoading) {
            return false;
        }
        isLoading = true;
        $element.addClass(LOADING_CLASSNAME);
        currentPage++;
        let data = {};

        if (!isNativePagination || !nextUrl) {
            data[loadmoreId] = currentPage;
            data.loadmore = currentPage;
        } else {
            nextUrl = nextUrl.split('/');
            nextUrl[nextUrl.length - 1] = nextUrl[nextUrl.length - 1].replace(/[0-9]/g, currentPage);
            nextUrl = nextUrl.join('/');
        }

        $.ajax(nextUrl || window.location.href, {
            dataType: 'html',
            data: data,
            error: function(response, status, error) {
                console.error(response);
            },
            success: function(response, status) {
                handleLoad(response);
            },
            complete: function() {
                isLoading = false;
                if (currentPage >= totalPages) {
                    $element.addClass(DISABLED_CLASSNAME);
                }
                setTimeout(function() {
                    $element.removeClass(LOADING_CLASSNAME);
                }, 500);
            }
        });
    }

    function handleLoad(response, status) {
        const $content = $('<div />').append(response).find('#' + loadmoreId);
        
        if ($content.length) {
            Dispatch.emit(eventKeys.LOADMORE_LOADED, {
                id: loadmoreId,
                content: $content
            });
        }
    }

    return {
        init, destroy
    };
};
