import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default el => {
    const ITEM_SELECTOR = '[data-sharebuttons-item]';

    let $element = $(el);

    const init = () => {
        $element.on('click', ITEM_SELECTOR, onItemClick);
    };

    const destroy = () => {
        $element.off('click');
    };

    function onItemClick(e) {
        e.preventDefault();
        openShareWindow($(e.currentTarget).attr('href'));
    }
        
    function openShareWindow(href) {
        const left = (screen.width / 2) - (600 / 2);
        const top = (screen.height / 2) - (600 / 2);
        window.open(href, 'shareWindow', 'height=600,width=600, top=' + top + ',left=' + left + ',toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
    }
    
    return {
        init, destroy
    };
};
