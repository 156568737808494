import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default el => {
    const ITEM_SELECTOR = '[data-profile-item]';
    const TOGGLE_SELECTOR = '[data-profile-itemtoggle]';
    const CONTENT_SELECTOR = '[data-profile-itemcontent]';
    const TEXT_SELECTOR = '[data-profile-itemtext]';

    const IS_EXPANDED_CLASSNAME = 'js-is-expanded';

    let $el = $(el);

    const init = () => {
        $el.on('click', TOGGLE_SELECTOR, onItemToggleClick);
        setTimeout(function() {
            initItems();
        }, 500);
    };

    const destroy = () => {
        $el.off('click');
    };

    function expandItem($item, doTween) {
        doTween = doTween !== undefined ? doTween : true;

        let $content = $item.find(CONTENT_SELECTOR);
        gsap.killTweensOf($content);
        $content.attr('style', '');
        $content.prop('hidden', false);

        let $text = $item.find(TEXT_SELECTOR);
        gsap.killTweensOf($text);
        let textFromHeight = $text.outerHeight();
        $text.find('.text-item').prop('hidden', false);

        $item.addClass(IS_EXPANDED_CLASSNAME);
        $item.find(TOGGLE_SELECTOR).attr('aria-expanded', true);

        Components.parse($item);

        if (!doTween) {
            return;
        }

        let contentToHeight = $content.outerHeight();
        let textToHeight = $text.outerHeight();

        gsap.timeline({
            onComplete: function() {
                $content.attr('style', '');
                $text.attr('style', '').find('.text-item').attr('style', '');
            }
        })
            .fromTo($content, {
                duration: 0.5,
                height: 0
            }, {
                height: contentToHeight,
                ease: 'cubic.inOut'
            }, 'anim')
            .fromTo($content,  {
                duration: 0.5,
                opacity: 0
            }, {
                opacity: 1,
                ease: 'sine.in'
            }, 'anim')
            .fromTo($text, {
                duration: 0.5,
                height: textFromHeight
            }, {
                height: textToHeight,
                ease: 'cubic.inOut'
            }, 'anim')
            .fromTo($text.find('.text-item'), {
                duration: 0.5,
                opacity: 0
            }, {
                opacity: 1,
                ease: 'sine.in'
            }, 'anim');
    }

    function collapseItem($item, doTween) {
        doTween = doTween === true || doTween === false ? doTween : true;

        let $content = $item.find(CONTENT_SELECTOR);
        gsap.killTweensOf($content);
        $content.attr('style', '');

        let $text = $item.find(TEXT_SELECTOR);

        function doCollapseItem() {
            $content
                .attr('style', '')
                .prop('hidden', true);
            $text
                .attr('style', '')
                .find('.text-item')
                .prop('hidden', true)
                .attr('style', '');

            $item.removeClass(IS_EXPANDED_CLASSNAME);
            $item.find(TOGGLE_SELECTOR).attr('aria-expanded', false);
            Components.destroy($item.find('[data-module-inited]'));
        }

        if (!doTween) {
            doCollapseItem();
            return;
        }

        let contentFromHeight = $content.outerHeight();
        let textFromHeight = $text.outerHeight();

        // Calculate the new text height
        $text.find('.text-item').css({
            height: 0,
            overflow: 'hidden'
        });

        let textToHeight = $text.outerHeight();

        $text.find('.text-item').attr('style', '');

        gsap.timeline({
            onComplete: function() {
                doCollapseItem();
            }
        })
            .fromTo($content, {
                duration: 0.5,
                height: contentFromHeight
            }, {
                height: 0,
                ease: 'cubic.inOut'
            }, 'anim')
            .fromTo($text,  {
                duration: 0.5,
                height: textFromHeight
            }, {
                height: textToHeight,
                ease: 'cubic.inOut'
            }, 'anim')
            .to($text.find('.text-item'), {
                duration: 0.5,
                opacity: 0,
                ease: 'sine.in'
            }, 'anim');
    }

    function toggleItem($item) {
        if ($item.hasClass(IS_EXPANDED_CLASSNAME)) {
            collapseItem($item);
        } else {
            expandItem($item);
        }
    }

    function onItemToggleClick(e) {
        let $item = $(e.currentTarget).closest(ITEM_SELECTOR);
        if (!$item.length) {
            return;
        }
        e.preventDefault();
        toggleItem($item);
    }

    function initItems() {
        let $expandedItem;
        $el.find(ITEM_SELECTOR + ':not(.' + IS_EXPANDED_CLASSNAME + ')').each(function() {
            collapseItem($(this), false);
            if (!$expandedItem && window.location.hash && window.location.hash === '#' + $(this).attr('id')) {
                $expandedItem = $(this);
            }
        });
        if ($expandedItem) {
            // Remove the hashbang
            window.location.hash = '';
            if (window.history && window.history.replaceState) {
                history.replaceState(null, document.title, window.location.pathname + window.location.search);
            }
            expandItem($expandedItem, false);
        }
    }


    return {
        init, destroy
    };
};
