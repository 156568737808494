import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const ITEM_SELECTOR = '[data-text-item]';
    const TOGGLE_SELECTOR = '[data-text-itemtoggle]';
    const TOGGLE_EXPAND_SELECTOR = '.block-text-expandable__toggle--expand';
    const CONTENT_SELECTOR = '[data-text-itemcontent]';
    const TEXT_SELECTOR = '[data-text-itemtext]';
    const IS_EXPANDED_CLASSNAME = 'js-is-expanded';

    let $el = $(el);

    const init = () => {
        $el.on('click', TOGGLE_SELECTOR, onItemToggleClick);

        requestAnimationFrame(function() {
            if (window.location.hash !== '') {
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, 'window.location.pathname + window.location.search');
                }
            }
        });
    };

    const destroy = () => {
        $el.off('click');
    };

    function expandItem(doTween) {
        doTween = doTween !== undefined ? doTween : true;

        let $content = $el.find(CONTENT_SELECTOR);
        gsap.killTweensOf($content);

        $el.addClass(IS_EXPANDED_CLASSNAME);
        $el.find(TOGGLE_SELECTOR).attr('aria-expanded', true);

        if (!doTween) {
            return;
        }

        $content.css('height', 'auto');
        let contentToHeight = $content.outerHeight();
        $content.css('height', 0);

        $el.find(TOGGLE_EXPAND_SELECTOR).css({ display: 'none' });

        gsap.timeline({
            onComplete: function() {
                $content.attr('height', 'auto');
            }
        })
            .to($content,  {
                duration: 0.5,
                height: contentToHeight,
                ease: 'cubic.inOut'
            })
            .to($content, {
                duration: 0.5,
                opacity: 1,
                ease: 'sine.in'
            }, 0.2);
    }

    function collapseItem(doTween) {
        doTween = doTween === true || doTween === false ? doTween : true;

        let $content = $el.find(CONTENT_SELECTOR);
        gsap.killTweensOf($content);

        $el.removeClass(IS_EXPANDED_CLASSNAME);
        $el.find(TOGGLE_SELECTOR).attr('aria-expanded', false);

        if (!doTween) {
            $content.attr('height', '0');
            $el.find(TOGGLE_EXPAND_SELECTOR).css({ display: '' });
            return;
        }

        $content.css('height', 'auto');
        let contentToHeight = $content.outerHeight();
        $content.css('height', contentToHeight);

        gsap.timeline({
            onComplete: function() {
                $content.attr('height', 0);
                $el.find(TOGGLE_EXPAND_SELECTOR).css({ display: '' });
            }
        })
            .to($content,  {
                duration: 0.1,
                opacity: 1,
                ease: 'sine.in'
            })
            .to($content, {
                duration: 0.4,
                height: 0,
                ease: 'cubic.inOut'
            }, 0.1);
    }

    function toggleItem() {
        if ($el.hasClass(IS_EXPANDED_CLASSNAME)) {
            collapseItem();
        } else {
            expandItem();
        }
    }

    function onItemToggleClick(e) {
        e.preventDefault();
        toggleItem();
    }

    return {
        init, destroy
    };
};
