import $ from 'jquery';
import Viewport from '../core/Viewport';
import Inview from '../lib/Inview';

import * as eventKeys from '../lib/events';

export default el => {
    
    let $element = $(el);
    let $map = $element.find('[data-map]');
    let mapLoaded = false;
    
    const onScroll = () => {
        if (!mapLoaded && Inview.inViewport($element, 0)) {
            mapLoaded = true;
            $map.find('img').addClass('lazyload lazypreload');
            Viewport.off('scroll', onScroll);
        }
    }
    
    Viewport.on('scroll', onScroll);
    
    setTimeout(onScroll, 0);

    return {
        destroy() {
            Viewport.off('scroll', onScroll);
        }
    };
};

