import $ from 'jquery';
import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import Config from './core/Config';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import Browser from './lib/Browser';
import * as eventKeys from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const $body = $('body');

let scrollingLocked = false;
let scrollBeforeLock = null;

const init = () => {
    Viewport.init();
    Components.init();
    //Viewport.initTabbing(); // disabled to keep it from colliding with the old behavior in Browser.js
    ViewportHeight.init();
    lazySizes.init();

    Dispatch.on(eventKeys.MENU_OPEN, onModalOpen, true);
    Dispatch.on(eventKeys.MENU_CLOSED, onModalClosed, true);
    Dispatch.on(eventKeys.MODAL_OPEN, onModalOpen, true);
    Dispatch.on(eventKeys.MODAL_CLOSED, onModalClosed, true);
    
    Browser.resetTabbing();
    measureScrollbarWidth();
};

const onModalOpen = () => {
    lockScroll();
}

const onModalClosed = () => {
    unlockScroll();
}

const measureScrollbarWidth = () => {
    // Force vertical scrollbar
    $body.css({ height: '200%' });

    // Measure body width
    const bodyWidth = $body.width();

    // Lock scrolling to remove vertical scrollbar
    lockScroll();

    // Measure the missing scrollbar width and cache to config
    Config.set('scrollbarWidth', Math.abs(bodyWidth - $body.width()));

    unlockScroll();
}

const lockScroll = () => {
    scrollingLocked = true;
    scrollBeforeLock = Viewport.scrollTop;

    $body.css({
        overflow: 'hidden',
        height: '100%'
    });

    $('html').css({
        marginRight: Config.get('scrollbarWidth') || 0
    });

    Dispatch.emit(eventKeys.SCROLLING_LOCKED);
}

const unlockScroll = () => {
    scrollingLocked = false;

    $body.css({
        overflow: '',
        height: ''
    });

    $('html').css({
        marginRight: ''
    });

    window.scrollTo(0, scrollBeforeLock);

    Dispatch.emit(eventKeys.SCROLLING_RELEASED);
}

require('doc-ready')(init);
