import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import * as eventKeys from '../lib/events';

gsap.registerPlugin(ScrollToPlugin);

export default el => {
    const SCROLLED_CLASSNAME = '-scrolled';
    const SCROLLED_THRESHOLD = 0.1;
    const IMAGE_HIDDEN_CLASSNAME = '-image-hidden';
    const IMAGE_SELECTOR = '[data-articlehero-image]';
    const IMAGE_OUTER_SELECTOR = '[data-articlehero-image-outer]';
    const SCROLLHELPER_SELECTOR = '[data-articlehero-scrollhelper]';

    let $element = $(el);
    let classList = $element[0].classList;
    let imageHidden = classList.contains(IMAGE_HIDDEN_CLASSNAME);
    let scrolled = classList.contains(SCROLLED_CLASSNAME);
    let $image = $element.find(IMAGE_SELECTOR);
    let $imageOuter = $element.find(IMAGE_OUTER_SELECTOR);
    let timeline = null;
    let lastWinW = null;
    let imageH = null;

    const init = () => {
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        
        onResize();

        $element
            .on('click', SCROLLHELPER_SELECTOR, onScrollHelperClick)
            .on('mouseenter', SCROLLHELPER_SELECTOR, onScrollHelperMouseEnter)
            .on('mouseleave', SCROLLHELPER_SELECTOR, onScrollHelperMouseLeave);
    };

    const destroy = () => {
        $element.off('click mouseenter mouseleave');
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
    };

    /* --- Event listeners ---------------------------------------------------------------- */
    function onResize(e) {
        let winW = Viewport.width;

        if (winW === lastWinW) {
            return false;
        }

        $image
            .css({
                height: '',
                position: ''
            })
            .css({
                height: $imageOuter.height(),
                position: 'fixed'
            });

        imageH = $imageOuter.height();

        if (!timeline) {
            createTimeline();
        }

        lastWinW = winW;
    }

    function onScroll(e) {
        if (!timeline) {
            return false;
        }

        let scrollTop = Viewport.scrollTop;

        let perc = scrollTop / imageH;

        if (perc < 0) {
            perc = 0;
        }
        if (perc > 1) {
            perc = 1;
        }

        if (perc !== timeline.progress()) {
            timeline.progress(perc);
        }

        if (perc >= SCROLLED_THRESHOLD) {
            if (!scrolled) {
                classList.add(SCROLLED_CLASSNAME);
                scrolled = true;
            }
        } else {
            if (scrolled) {
                classList.remove(SCROLLED_CLASSNAME);
                scrolled = false;
            }
        }

        if (perc >= 1) {
            if (!imageHidden) {
                classList.add(IMAGE_HIDDEN_CLASSNAME);
                imageHidden = true;
            }
        } else {
            if (imageHidden) {
                classList.remove(IMAGE_HIDDEN_CLASSNAME);
                imageHidden = false;
            }
        }

    }

    function onScrollHelperClick(e) {
        e.preventDefault();

        var $target = $(e.currentTarget);
        var scrollTo = 0;

        if (!classList.contains(SCROLLED_CLASSNAME)) {
            var $scrollHelperTarget = $($target.attr('href'));
            scrollTo = $scrollHelperTarget.offset().top;
        }

        gsap.to(window, {
            duration: 0.75,
            scrollTo: {
                y: scrollTo
            },
            ease: 'cubic.inOut'
        });

        e.currentTarget.classList.remove('-hovered');

    }

    function onScrollHelperMouseEnter(e) {
        e.currentTarget.classList.add('-hovered');
    }

    function onScrollHelperMouseLeave(e) {
        e.currentTarget.classList.remove('-hovered');
    }

    /* --- Private methods --------------------------------------------------------------- */
    function createTimeline() {
        destroyTimeline();

        timeline = gsap.timeline({
            paused: true
        })
            .fromTo($image,  {
                duration: 1, yPercent: 0.000001
            }, {
                yPercent: -10,
                ease: 'linear.none',
                force3D: true
            }, 'image');

        classList.add('-js-initialized');
        
        onScroll(null);
    }

    function destroyTimeline() {
        if (timeline) {
            timeline.kill();
            timeline = null;
        }
    }

    return {
        init, destroy
    };
};
