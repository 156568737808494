import $ from 'jquery';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import * as eventKeys from '../../lib/events';

export default el => {
    const LIST_SELECTOR = '[data-featureslider-list]';

    let $element = $(el);

    const init = () => {
        $element.on('dragstart', 'img', function(e) {
            e.preventDefault();
        });

        Dispatch.on(eventKeys.LOADMORE_LOADED, onLoadMoreLoaded);
    };

    const destroy = () => {
        $element.off('dragstart');

        Dispatch.off(eventKeys.LOADMORE_LOADED, onLoadMoreLoaded);
    };

    /* --- Event handlers ---------------------------------------------------------------- */
    function onLoadMoreLoaded(key, data) {
        if (data.id !== $element.attr('id')) {
            return false;
        }
        let $content = data.content;
        let $list = $content.find('[data-eventslist-list]');
        appendEvents($list);
    }

    /* --- Private methods --------------------------------------------------------------- */
    function appendEvents($list) {

        let $eventsList = $element.find('[data-eventslist-list]'),
            eventslistHeight = $eventsList.height(),
            status,
            $statusList,
            $targetList,
            $items;

        $list.find('li').attr('data-loadmore-added', true);
        $list.find('[data-eventslist-statuslist]').each(function() {
            $statusList = $(this);
            status = $statusList.data('eventslist-statuslist');
            // Either merge with existing status list, or just append
            $targetList = $eventsList.find('[data-eventslist-statuslist="' + status + '"]');
            if ($targetList.length) {
                $targetList.append($statusList.children());
            } else {
                $eventsList.append($statusList.parent());
            }
        });

        let transition = gsap.timeline({
                paused: true
            }),
            $addedItems = $eventsList.find('[data-loadmore-added]'),
            addedItem,
            label,
            scrollTop = $eventsList.offset().top + eventslistHeight;

        for (let i = 0; i < $addedItems.length; ++i) {
            label = 'item+=' + (i * 0.05);
            addedItem = $addedItems[i];
            transition
                .fromTo(addedItem,  {
                    duration: 0.2,
                    opacity: 0
                }, {
                    opacity: 1,
                    ease: 'sine.in'
                }, label)
                .fromTo(addedItem,  {
                    duration: 0.5,
                    scale: 0.85,
                    yPercent: -100,
                    transformOrigin: 'center bottom'
                }, {
                    scale: 1,
                    yPercent: 0,
                    transformOrigin: 'center bottom',
                    ease: 'cubic.out'
                }, label)
                .fromTo($(addedItem).children(),  {
                    duration: 0.25,
                    opacity: 0
                }, {
                    opacity: 1,
                    delay: 0.1,
                    ease: 'sine.in'
                }, label);
        }

        transition
            .fromTo($eventsList,  {
                duration: transition.duration() * 0.3,
                height: eventslistHeight
            }, {
                height: $eventsList.height(),
                ease: 'cubic.in',
                onComplete: function() {
                    $eventsList.attr('style', '');
                }
            }, 0);

        gsap.to(window,  {
            duration: transition.duration() * 0.75,
            delay: transition.duration() * 0.10,
            scrollTo: { y: scrollTop - 50 },
            ease: 'cubic.inOut'
        });

        $addedItems.removeAttr('data-loadmore-added');

        requestAnimationFrame(function() {
            transition.play();
        });
    }

    return {
        init, destroy
    };
};
