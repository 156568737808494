/*

 VIEWPORT

 ~ Dependencies ~

 https://github.com/callmecavs/jump.js

 ~ Usage ~

 import Viewport from '@vaersaagod/tools/Viewport';

 // When you boot up your app
 Viewport.init();

 ~ Events ~

 // Breakpoint events
 Viewport.on('breakpoint', handler);

 // Viewport/tab is focused or active
 Viewport.on('focus', handler);

 // Viewport/tab is blurred or in the background
 Viewport.on('blur', handler);

 // All other standard events are proxied to the window object (scroll and resize are throttled at 60 fps)
 Viewport.on('mousedown', handler);

 // Remove listener
 Viewport.off('mousedown', handler);

 ~ Methods ~
 Viewport.init()
 Viewport.visible(elementOrDom, threshold) // Is the element in viewport
 Viewport.scrollTo(elementOrDom, options) // Animate scrollTo with jump.js
 Viewport.lockScrolling();
 Viewport.releaseScrolling();
 Viewport.initTabbing();
 Viewport.lockTabbing(elementToLimitTabbingTo, elementToReceiveFocus)
 Viewport.releaseTabbing(elementToReceiveFocus)
 Viewport.addTabbableSelectors(commaSeparatedSelectors)
 Viewport.setTabbableSelectors(commaSeparatedSelectors)

 ~ Getters ~
 Viewport.breakpoint
 Viewport.width
 Viewport.height
 Viewport.size // object with width and height
 Viewport.scrollTop
 Viewport.scrollLeft
 Viewport.scroll // object with scrollTop and scrollLeft

 */

import $ from 'jquery';
import { deferredCallback } from './utils';

/* global SCREENS */
// noinspection JSUnresolvedVariable
const screens = { none: 0, ...SCREENS };
const BREAKPOINTS = Object.keys(screens).reduce((carry, name) => {
    const size = parseInt(screens[name], 10);
    if (Number.isNaN(size)) {
        return carry;
    }
    return carry.concat({ name, size });
}, []).sort((a, b) => a.size - b.size);

const TAB_INDEX_KEY = '__tabIndex';
const THROTTLED_EVENTS = { resize: [], scroll: [] };
let body;

let INITED = false;
let SIZE = { width: 0, height: 0 };
let SCROLL = { top: 0, left: 0 };
let SCROLLING_LOCKED = false;
let BREAKPOINT = BREAKPOINTS[0];
let TABBABLE_SELECTORS = ['a', 'input', 'button', 'select', 'textarea', 'iframe'];
let TABBING_LOCKED = false;
let TABBING_TARGET = null;

let TABBING_INITED = false;

let scrollBeforeLock = 0;

const on = (type, listener) => {
    if (Object.keys(THROTTLED_EVENTS).indexOf(type) > -1) {
        THROTTLED_EVENTS[type].push(listener);
    } else {
        window.addEventListener(type, listener);
    }
};

const once = (type, listener) => {
    const callback = e => {
        listener(e);
        window.removeEventListener(type, callback);
    };
    window.addEventListener(type, callback);
};

const off = (type, listener) => {
    if (Object.keys(THROTTLED_EVENTS).indexOf(type) > -1) {
        const index = THROTTLED_EVENTS[type].indexOf(listener);
        if (index > -1) {
            THROTTLED_EVENTS[type].splice(index, 1);
        }
    } else {
        window.removeEventListener(type, listener);
    }
};

const setBreakpoint = ({ name, size }) => {
    if (name === BREAKPOINT.name && size === BREAKPOINT.size) {
        return;
    }
    const event = new CustomEvent('breakpoint', { detail: { old: BREAKPOINT, current: { name, size } } });
    BREAKPOINT = { name, size };
    window.dispatchEvent(event);
};

const resizeHandler = e => {
    SIZE = {
        width: window.innerWidth,
        height: window.innerHeight
    };
    THROTTLED_EVENTS.resize.forEach(listener => listener(e));
};

const scrollHandler = e => {
    SCROLL = {
        top: (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop,
        left: (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft
    };
    THROTTLED_EVENTS.scroll.forEach(listener => listener(e));
};

const visibilityChangeHandler = () => {
    if (document.visibilityState === 'hidden') {
        window.dispatchEvent(new CustomEvent('blur'));
    } else if (document.visibilityState === 'visible') {
        window.dispatchEvent(new CustomEvent('focus'));
    }
};

const visible = (element, threshold = 0) => {
    const rect = $(element).get(0).getBoundingClientRect();
    return ((rect.top < (SIZE.height + threshold)) && (rect.bottom > -threshold));
};

const scrollTo = (value, opts = {}) => {

};

const blockScrolling = e => {
    e.preventDefault();
};

const lockScrolling = () => {
    SCROLLING_LOCKED = true;
    scrollBeforeLock = SCROLL.top;
    body.css({ overflow: 'hidden' });
    document.addEventListener('touchmove', blockScrolling, false);
};

const releaseScrolling = () => {
    SCROLLING_LOCKED = false;
    body.css({ overflow: null });
    window.scrollTo(0, scrollBeforeLock);
    document.removeEventListener('touchmove', blockScrolling);
};

const addTabbableSelectors = commaSeparatedSelectors => {
    const selectors = commaSeparatedSelectors.split(',');
    if (selectors.length) {
        TABBABLE_SELECTORS = TABBABLE_SELECTORS.concat(selectors);
    }
};

const setTabbableSelectors = commaSeparatedSelectors => {
    const selectors = commaSeparatedSelectors.split(',');
    if (selectors.length) {
        TABBABLE_SELECTORS = selectors;
    }
};

const getTabbableSelectors = () => TABBABLE_SELECTORS.join(',');

const lockTabbing = (elementToLimitTabbingTo, elementToReceiveFocus = false) => {
    if (!TABBING_INITED) throw new Error('Tabbing is not initialised');
    if (TABBING_LOCKED) throw new Error(`Tabbing is already locked to ${TABBING_TARGET}`);

    TABBING_LOCKED = true;
    TABBING_TARGET = elementToLimitTabbingTo;

    // Disable tabbing for body and all tabbable children
    body.attr('tabIndex', -1).find(getTabbableSelectors()).attr('tabIndex', -1);

    // Enable tabbing for given selector and all tabbable children
    $(elementToLimitTabbingTo).attr('tabIndex', null).find(getTabbableSelectors()).each(node => {
        $(node).attr('tabIndex', node[TAB_INDEX_KEY]);
    });

    // If any element should receive focus, focus it
    if (elementToReceiveFocus) {
        $(elementToReceiveFocus).focus();
    }
};

const releaseTabbing = (elementToReceiveFocus = false) => {
    if (!TABBING_INITED) throw new Error('Tabbing is not initialised');
    TABBING_LOCKED = false;
    // Reset tabbing for body and all tabbable children
    body.attr('tabIndex', null).find(getTabbableSelectors()).each(node => {
        const child = $(node);
        if (typeof node[TAB_INDEX_KEY] === 'undefined') {
            node[TAB_INDEX_KEY] = child.attr('tabIndex') || null;
        }
        child.attr('tabIndex', node[TAB_INDEX_KEY]);
    });

    // Disable tabbing for elements with data-disable-tabbing attribute and all tabbable children
    $('[data-disable-tabbing]').attr('tabIndex', -1).find(getTabbableSelectors()).attr('tabIndex', -1);

    // If any element should receive focus, focus it
    if (elementToReceiveFocus) {
        $(elementToReceiveFocus).focus();
    }
};

const enableTabbingOnElement = (elementToEnableTabbingOn, elementToReceiveFocus = false) => {
    if (!TABBING_INITED) throw new Error('Tabbing is not initialised');
    $(elementToEnableTabbingOn).attr('tabIndex', null).find(getTabbableSelectors()).each(node => {
        $(node).attr('tabIndex', node[TAB_INDEX_KEY]);
    });

    if (elementToReceiveFocus) {
        $(elementToReceiveFocus).focus();
    }
};

const disableTabbingOnElement = (elementToDisableTabbingOn, elementToReceiveFocus = false) => {
    if (!TABBING_INITED) throw new Error('Tabbing is not initialised');
    $(elementToDisableTabbingOn).attr('tabIndex', -1).find(getTabbableSelectors()).attr('tabIndex', -1);

    if (elementToReceiveFocus) {
        $(elementToReceiveFocus).focus();
    }
};

const initTabbing = () => {
    TABBING_INITED = true;
    releaseTabbing();
};

const init = (fps = 60) => {
    if (!INITED) {
        INITED = true;
        body = $('body');

        // Add our listeners
        window.addEventListener('orientationchange', resizeHandler);
        window.addEventListener('resize', deferredCallback(resizeHandler, fps));
        window.addEventListener('scroll', deferredCallback(scrollHandler, fps));
        document.addEventListener('visibilitychange', visibilityChangeHandler);

        // Listen for breakpoint change
        BREAKPOINTS.forEach(({ name, size }, i) => {
            const queries = ['screen', `(min-width:${size}px)`];
            if (i < BREAKPOINTS.length - 1) {
                queries.push(`(max-width:${BREAKPOINTS[i + 1].size - 1}px)`);
            }
            const mq = window.matchMedia(queries.join(' and '));
            const onChange = e => {
                if (e.matches) {
                    setBreakpoint({ name, size });
                }
            };
            try {
                mq.addEventListener('change', onChange);
            } catch (error) {
                mq.addListener(onChange);
            }
            // Sets initial breakpoint
            if (mq.matches) {
                setBreakpoint({ name, size });
            }
        });

        // Force the events to fire initially
        resizeHandler();
        scrollHandler();
    }
};

// Public api for module
export default {
    init,
    visible,
    on,
    once,
    off,
    scrollTo,
    lockScrolling,
    releaseScrolling,
    initTabbing,
    lockTabbing,
    releaseTabbing,
    enableTabbingOnElement,
    disableTabbingOnElement,
    addTabbableSelectors,
    setTabbableSelectors,
    get tabbingLocked() {
        return TABBING_LOCKED;
    },
    get scrollingLocked() {
        return SCROLLING_LOCKED;
    },
    get breakpoint() {
        return BREAKPOINT;
    },
    get width() {
        return SIZE.width;
    },
    get height() {
        return SIZE.height;
    },
    get size() {
        return SIZE;
    },
    get scroll() {
        return SCROLL;
    },
    get scrollTop() {
        return SCROLL.top;
    },
    get scrollLeft() {
        return SCROLL.left;
    }
};
