import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const BP_PLAYAREA_EXPANDABLE = 980;

    let $element = $(el);

    let $embed = null,
        $playArea = $element.find('.videos-module__play-area'),
        $playItem = $element.find('.videos-module__item'),
        $navArea = $element.find('.videos-module__navigation'),
        $inner = $element.find('.videos-module__inner'),
        $items = $element.find('.videos-module__list-item'),
        isPlayView = false;

    const init = () => {
        $element.on('click', '[data-trigger]', onClick);
    };

    const destroy = () => {
        $element.off('click');
    };

    function onClick(e) {
        e.preventDefault();
        playVideo($(this));
    }


    /* --- Private methods --------------------------------------------------------------- */

    function playVideo($link) {
        let from = $link.data('trigger');
        let videoId = $link.data('video-id');
        let isAnimating = false;

        if (!isPlayView && Viewport.breakpoint.size >= BP_PLAYAREA_EXPANDABLE) {
            showPlayView();
            isAnimating = true;
        }

        let $selectedListItem = $items.filter('[data-video-id="' + videoId + '"]');
        let $currentlyActiveListItem = $items.filter('.-active-video');

        if ($selectedListItem.length > 0) {
            let data = $selectedListItem.data('embed-code');

            $embed = $(data.embedCode);
            let $oldIframes = $playArea.find('iframe');

            setTimeout(function() {
                $playArea.append($embed);
                $embed.css({ opacity: 0 });
                gsap.to($embed, {
                    duration: 0.4, opacity: 1, onComplete: function() {
                        $playItem.css({ opacity: 0 });
                        $oldIframes.remove();
                    }
                });
            }, isAnimating ? 800 : 1);

            if (!isAnimating && Viewport.breakpoint.size >= BP_PLAYAREA_EXPANDABLE) {
                if ($selectedListItem.data('video-id') !== $currentlyActiveListItem.data('video-id')) {
                    $items.css({ width: '33.333333%' });
                    $currentlyActiveListItem.css({ opacity: 0, width: '0%' }).removeClass('-active-video');

                    let tl = gsap.timeline({ paused: true });
                    tl.to($selectedListItem, { duration: 0.3, opacity: 0 }, 0.5);
                    tl.to($currentlyActiveListItem,  { duration: 0.4, width: '33.333333%', ease: 'sine.inOut' }, 0.7);
                    tl.to($selectedListItem,  {
                        duration: 0.4, width: '0%', ease: 'sine.inOut', onComplete: function() {
                            $currentlyActiveListItem.removeClass('-active-video');
                            $selectedListItem.addClass('-active-video');
                        }
                    }, 0.7);
                    tl.to($currentlyActiveListItem, { duration: 0.3, opacity: 1 }, 1.0);

                    tl.play();
                }
            } else {
                $currentlyActiveListItem.removeClass('-active-video');
                $selectedListItem.addClass('-active-video');
            }
        }
    }

    function showPlayView() {
        gsap.to($navArea, {
            duration: 0.1, opacity: 0, onComplete: function() {
                $navArea.detach();
                $inner.css({ 'width': $inner.outerWidth(), 'max-width': 'none' });

                gsap.to($inner, {
                    duration: 0.6, width: $element.outerWidth(), ease: 'quint.inOut', onComplete: function() {
                        $inner.css({ width: '100%' });
                    }
                });

                gsap.to($playArea, {
                    duration: 0.6, width: $element.outerWidth(), paddingRight: 0, ease: 'quint.inOut', onComplete: function() {
                        $playArea.css({ width: '100%' });
                        $inner.append($navArea);
                        $element.addClass('-playarea-activated');

                        gsap.from($navArea, {
                            duration: 0.3, delay: 0.5, height: 0, onComplete: function() {
                                gsap.to($navArea, { duration: 0.4, opacity: 1 });
                                triggerResizedEvent();
                            }
                        });
                    }
                });

                let estimatedHeight = ($element.outerWidth() * (9 / 16)) + 140;
                let scrollTop = Viewport.scrollTop;
                let viewportHeight = Viewport.height;
                let playerOffset = $playArea.offset().top;

                if ((playerOffset + estimatedHeight > scrollTop + viewportHeight) || (playerOffset < scrollTop)) {
                    let targetY = playerOffset - 80;

                    if (estimatedHeight < viewportHeight) {
                        targetY = playerOffset - (viewportHeight - estimatedHeight) / 2;
                    }

                    gsap.to(window, { duration: 1.4, scrollTo: { y: targetY }, ease: 'quart.inOut' });
                }
            }
        });

        isPlayView = true;
    }

    function triggerResizedEvent() {
        Dispatch.emit(eventKeys.VIDEO_MODULE_RESIZED);
    }

    return {
        init, destroy
    };
};
