import $ from 'jquery';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../../lib/events';

export default el => {
    const ITEM_SELECTOR = '[data-textnavigation-item]';
    const ITEM_LINK_SELECTOR = ITEM_SELECTOR + ' a';
    const ITEM_ARROW_SELECTOR = '[data-textnavigation-arrow]';
    const ITEM_ARROW_LINE_SELECTOR = '[data-textnavigation-arrowline]';

    let $element = $(el);
    let breakpoint = Viewport.breakpoint.name;
    let winW = Viewport.width;

    const init = () => {
        $element
            .on('mouseenter', ITEM_LINK_SELECTOR, onMouseEnter)
            .on('mouseleave', ITEM_LINK_SELECTOR, onMouseLeave)
            .find(ITEM_LINK_SELECTOR)
            .each(function() {
                createItemTransition($(this));
            });

        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        $element
            .off('mouseenter mouseleave')
            .find(ITEM_LINK_SELECTOR)
            .each(function() {
                killItemTransition($(this));
            });
    };

    /* --- Event listeners ---------------------------------------------------------------- */

    function onMouseEnter(e) {
        let $item = $(e.currentTarget);
        let transition = $item.data('transition');
        if (transition) {
            transition.play(0);
        }
    }

    function onMouseLeave(e) {
        let $item = $(e.currentTarget);
        let itemData = $item.data();
        if (itemData.transition) {
            itemData.transition.reverse();
        }
    }

    function onBreakpoint(e) {
        if (Viewport.breakpoint.name !== breakpoint) {
            breakpoint = Viewport.breakpoint.name;
            createItemTransitions();
        }
    }

    /* --- Private methods --------------------------------------------------------------- */

    function createItemTransitions() {
        // Re-create transitions
        $element.find(ITEM_LINK_SELECTOR).each(function() {
            createItemTransition($(this));
        });
    }

    function createItemTransition($item) {

        killItemTransition($item);

        // Get arrow
        let $arrow = $item.find(ITEM_ARROW_SELECTOR);
        if (!$arrow.length) {
            return false;
        }

        // Get line
        let $line = $arrow.children(ITEM_ARROW_LINE_SELECTOR);
        if (!$line.length) {
            return false;
        }

        let lineWidth = $line.outerWidth();
        let lineGrow = lineWidth * 0.75;

        let transition = gsap.timeline({
            paused: true
        })
            .to($arrow, {
                duration: 0.35,
                x: lineGrow,
                force3D: true,
                ease: 'cubic.inOut'
            }, 'forward')
            .to($line,  {
                duration: 0.35,
                x: -lineGrow,
                width: lineWidth + lineGrow,
                force3D: true,
                transformOrigin: 'right center',
                ease: 'cubic.inOut'
            }, 'forward');

        $item.data('transition', transition);

    }

    function killItemTransition($item) {
        let transition = $item.data('transition');
        if (transition) {
            transition.pause(0).kill();
            $item.data('transition', null);
        }
    }

    return {
        init, destroy
    };
};
