import $ from 'jquery';
import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Browser from '../lib/Browser';
import * as eventKeys from '../lib/events';

export default el => {

    const BP_MAP_HIDDEN = 750;

    var $element = $(el),
        $map = $element.find('[data-map]'),
        $menuInner = $element.find('.mega-menu__inner'),
        $closeBtn = $element.find('.mega-menu__close-button'),
        $tabbableElements = $element.find('a, input, button'),
        focusToThisElementWhenClosing = null,
        mapLoaded = false;

    const init = () => {
        $element.addClass('-js-enabled');

        $element.on('click', '.mega-menu__block-item-button', function(e) {
            toggleSubmenu($(this));
        });

        $element.on('mouseenter', '.mega-menu__block-item-button', function(e) {
            buttonMouseEnter($(this));
        });

        $element.on('mouseleave', '.mega-menu__block-item-button', function(e) {
            buttonMouseLeave($(this));
        });

        Viewport.on('breakpoint', onBreakpoint);

        Dispatch.on(eventKeys.MENU_OPEN, onMenuOpen, true);
        Dispatch.on(eventKeys.MENU_CLOSE, onMenuClose, true);

        // Override tabbing
        disableTabbing();
    };

    const destroy = () => {
        $element.off('click');
        $element.off('mouseenter');
        $element.off('mouseleave');

        Viewport.off('breakpoint', onBreakpoint);

        Dispatch.off(eventKeys.MENU_OPEN, onMenuOpen, true);
        Dispatch.off(eventKeys.MENU_CLOSE, onMenuClose, true);
    };

    /* --- Event listeners ---------------------------------------------------------------- */

    function onBreakpoint(e) {
        if ((e.detail.current.size < BP_MAP_HIDDEN)) {
            checkMap();
        }
    }

    function onMenuOpen(key, data) {
        openMenu();
    }

    function onMenuClose(key, data) {
        closeMenu();
    }


    /* --- Private methods --------------------------------------------------------------- */

    function openMenu() {
        enableTabbing();

        $element
            .css({ height: '0%', overflow: 'hidden' })
            .scrollTop(0);

        $menuInner.css({ opacity: 0 });

        gsap.killTweensOf($menuInner);
        gsap.to($menuInner, { duration: 0.4, delay: 0.2, opacity: 1, ease: 'sine.out' });
        gsap.to($element, {
            duration: 0.8,
            height: '100%',
            ease: 'quint.out',
            onComplete: function() {
                checkMap();
                Dispatch.emit(eventKeys.MENU_OPENED);
                $element.css({ overflow: '' });
            }
        });
    }

    function closeMenu() {
        disableTabbing();

        $element.css({ overflow: 'hidden' });

        gsap.to($menuInner, { duration: 0.2, opacity: 0, ease: 'sine.out' });
        gsap.to($element, {
            duration: 0.5,
            height: '0%',
            ease: 'quint.out',
            onComplete: function() {
                Dispatch.emit(eventKeys.MENU_CLOSED);
                $element.css({ height: '', overflow: '' });
                $menuInner.css({ opacity: '' });
            }
        });
    }

    function toggleSubmenu($btn) {
        const $submenu = $btn.parent().find('.mega-menu__block-item-submenu');

        if ($submenu.length > 0) {
            if ($submenu.hasClass('-is-open')) {
                closeSubmenu($submenu);
            } else {
                openSubmenu($submenu);
            }
        }
    }

    function openSubmenu($submenu) {
        $submenu.addClass('-is-open').css({ height: 'auto' });
    }

    function closeSubmenu($submenu) {
        $submenu.removeClass('-is-open').css({ height: '' });
    }

    function buttonMouseEnter($btn) {
        const $link = $btn.parent().find('.mega-menu__block-item-link.-top');
        $link.addClass('-button-hover');
    }

    function buttonMouseLeave($btn) {
        const $link = $btn.parent().find('.mega-menu__block-item-link.-top');
        $link.removeClass('-button-hover');
    }

    function checkMap() {
        if (!mapLoaded && (Viewport.breakpoint.size < BP_MAP_HIDDEN)) {
            mapLoaded = true;
            setTimeout(() => {
                $map.find('img').addClass('lazyload lazypreload');
            }, 0);
        }
    }

    function disableTabbing() {
        Browser.resetTabbing(focusToThisElementWhenClosing);
        
        focusToThisElementWhenClosing = null;

        $tabbableElements.attr({
            tabIndex: -1,
            'aria-hidden': true
        });
    }

    function enableTabbing() {
        // Store currently focused element
        focusToThisElementWhenClosing = document.activeElement || null;

        // Make tabbable items, er, tabbable (and nothing else, obviously)
        $tabbableElements.removeAttr('tabIndex aria-hidden');
        Browser.overrideTabbing('.page-header__mega-menu', $closeBtn);
    }

    return {
        init,
        destroy
    };
};
