import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import Flickity from 'flickity';
import * as eventKeys from '../lib/events';

export default el => {
    const LIST_SELECTOR = '[data-contentslider-list]';
    const NAV_SELECTOR = '[data-contentslider-nextprev]';
    const NAV_NEXT_SELECTOR = '[data-contentslider-next]';
    const NAV_PREV_SELECTOR = '[data-contentslider-prev]';
    const TABBABLE_SUB_ITEMS = ['a'];

    let $element = $(el);
    let winW = null;
    let slider = null;
    let flickityOpts = {};
    let currentSlideIndex = null;
    let $list;
    let $items;
    let $nav;
    let nextEnabled = null;
    let prevEnabled = null;
    let subItemFocused = false;

    const init = () => {
        $list = $element.find(LIST_SELECTOR);
        $items = $list.children();

        if ($items.length <= 1) {
            return false;
        }

        $nav = $element.find(NAV_SELECTOR);

        $element
            .on('dragstart', '*', function(e) {
                e.preventDefault();
            })
            .on('click', NAV_NEXT_SELECTOR, next)
            .on('click', NAV_PREV_SELECTOR, prev);

        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        $element.off('dragstart click');
        destroySlider();
        Viewport.off('resize', onResize);
    };

    
    /* --- Event handlers ---------------------------------------------------------------- */
    function onResize(e) {
        if (Viewport.width === winW) {
            return false;
        }
        winW = Viewport.width;
        createSlider();
    }

    function onSlideSelect() {
        maybeDisableNav();
    }

    function onSlideSettle() {
        maybeDisableNav();
    }

    function onTabbableSubItemFocus(e) {
        if (!slider) {
            return;
        }

        subItemFocused = true;

        // Prevent the browser from moving our slider all native and ugly like
        $element.find('*').scrollLeft(0);

        const index = $(e.currentTarget).closest('.flickity-slider > div').index();

        if (flickityOpts.groupCells) {
            slider.selectCell(index);
        } else {
            slider.select(index);
        }

    }

    function onTabbableSubItemBlur(e) {
        subItemFocused = false;
    }

    function onKeyUp(e) {
        if (!slider || !subItemFocused) {
            return;
        }
        switch (e.keyCode) {
            case 37:
                slider.previous();
                break;
            case 39:
                slider.next();
                break;
        }
    }

    function onDragStart() {
        $element.addClass('-is-dragging');
    }

    function onDragEnd() {
        $element.removeClass('-is-dragging');
    }

    /* --- Private methods --------------------------------------------------------------- */
    function createSlider() {

        destroySlider();

        let $item;
        let itemHeight;
        let totalItemWidth = 0;
        let tallestItemHeight = 0;

        $items.each(function() {
            $item = $(this);
            $item.width($item.width());
            totalItemWidth += $item.outerWidth();
            itemHeight = $item.height();
            if (itemHeight > tallestItemHeight) {
                tallestItemHeight = itemHeight;
            }
        });

        $items.height(tallestItemHeight);
        $list.height(tallestItemHeight);

        let breakpoint = Viewport.breakpoint.name;
        let isSmall = ['sm', 's', 'sp'].indexOf(breakpoint) > -1;

        flickityOpts = {
            pageDots: false,
            resize: false,
            setGallerySize: false,
            percentPosition: false,
            contain: true,
            prevNextButtons: false,
            groupCells: true,
            cellAlign: 'left'
        };

        // On narrow screens, work a little differently
        if (isSmall) {
            flickityOpts.groupCells = false;
            flickityOpts.contain = false;
            flickityOpts.freeScroll = true;
        }

        slider = new Flickity($list[0], flickityOpts);

        slider.on('select', onSlideSelect);
        slider.on('settle', onSlideSettle);
        slider.on('dragStart', onDragStart);
        slider.on('dragEnd', onDragEnd);

        $element
            .on('keyup', onKeyUp)
            .on('focus', TABBABLE_SUB_ITEMS.join(','), onTabbableSubItemFocus)
            .on('blur', TABBABLE_SUB_ITEMS.join(','), onTabbableSubItemBlur);

        maybeDisableNav();

    }

    function destroySlider() {
        if (!slider) {
            return false;
        }

        slider.off('select');
        slider.off('settle');
        slider.off('dragStart');
        slider.off('dragEnd');
        slider.destroy();
        slider = null;

        $items.attr('style', '');
        $list.attr('style', '');

        currentSlideIndex = 0;
        nextEnabled = prevEnabled = false;

        $element
            .off('focus blur keyup')
            .removeClass('-next-enabled -prev-enabled -has-slider');

    }

    function maybeDisableNav() {
        if (!slider || !slider.slides) {
            return;
        }

        if (!slider && (nextEnabled || prevEnabled)) {
            nextEnabled = prevEnabled = false;
            $element.removeClass('-next-enabled -prev-enabled');
            return;
        }

        let numSlides = slider.slides.length,
            currentSlide = slider.selectedIndex,
            enableNext = numSlides - 1 > currentSlide,
            enablePrev = currentSlide > 0;

        if (enableNext !== nextEnabled) {
            nextEnabled = enableNext;
            if (nextEnabled) {
                $element
                    .addClass('-next-enabled')
                    .find(NAV_NEXT_SELECTOR)
                    .removeAttr('tabIndex aria-hidden');
            } else {
                $element
                    .removeClass('-next-enabled')
                    .find(NAV_NEXT_SELECTOR)
                    .attr({
                        tabIndex: -1,
                        'aria-hidden': true
                    });
            }
        }

        if (enablePrev !== prevEnabled) {
            prevEnabled = enablePrev;
            if (prevEnabled) {
                $element
                    .addClass('-prev-enabled')
                    .find(NAV_PREV_SELECTOR)
                    .removeAttr('tabIndex aria-hidden');

            } else {
                $element
                    .removeClass('-prev-enabled')
                    .find(NAV_PREV_SELECTOR)
                    .attr({
                        tabIndex: -1,
                        'aria-hidden': true
                    });
            }
        }

    }

    function next(e) {
        if (e) {
            e.preventDefault();
        }
        if (!slider) {
            return false;
        }
        slider.next();
    }

    function prev(e) {
        if (e) {
            e.preventDefault();
        }
        if (!slider) {
            return false;
        }
        slider.previous();
    }


    return {
        init, destroy
    };
};
