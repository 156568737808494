import $ from 'jquery';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../../lib/events';

export default (el, props) => {
    const STATUS_ATTRIBUTE = 'data-eventsgrid-status';
    const ITEM_SELECTOR = '[data-eventsgrid-item]';
    const ITEM_STATUS_SELECTOR = '[data-eventitem-status]';
    const STATUS_LABEL_CLASSNAME = 'js-status-label';

    let $element = $(el);
    let displayStatuses = props.hasOwnProperty('displayStatuses') ? props.displayStatuses : true;
    let $items = $element.find(ITEM_SELECTOR);
    let statuses = props.statuses || {};
    let statusKeys = Object.keys(statuses);
    let elementWidth = null;
    let elementHeight = null;

    let numItems = null;
    let numCols = null;
    let numRows = null;
    let teaserWidth = null;

    const init = () => {
        $element.on('dragstart', 'img', function(e) {
            e.preventDefault();
        });

        Viewport.on('resize', onResize);
        Dispatch.on(eventKeys.LOADMORE_LOADED, onLoadMoreLoaded);

        onResize();

        $element.addClass('-js-initialized');
    };

    const destroy = () => {
        $element.off('dragstart');

        Viewport.off('resize', onResize);
        Dispatch.off(eventKeys.LOADMORE_LOADED, onLoadMoreLoaded);

        elementWidth = null;
        elementHeight = null;
        numItems = null;
        numCols = null;
        numRows = null;
        teaserWidth = null;
    };


    /* --- Event handlers ---------------------------------------------------------------- */
    function onResize(e) {
        updateGrid();
    }

    function onLoadMoreLoaded(key, data) {
        if (data.id !== $element.attr('id')) {
            return false;
        }

        let $content = data.content;
        let $items = $content.find('[data-eventsgrid-items]');

        appendItems($items);

        Dispatch.emit(eventKeys.DOM_MODIFIED, ITEM_SELECTOR)
    }

    /* --- Private methods --------------------------------------------------------------- */

    function appendItems($items) {
        let scrollTop = Viewport.scrollTop,
            $container = $element.find('[data-eventsgrid-items]'),
            $addedTeasers = $items.find(ITEM_SELECTOR),
            colsPerRow = props.colsPerRow || {},
            itemIndex = $container.find(ITEM_SELECTOR).length,
            nthClasses;

        if (!$addedTeasers.length) {
            return false;
        }

        let transition = gsap.timeline({
            paused: true
        });

        $addedTeasers
            .each(function() {
                // Correct nth- classnames. This whole debacle is a drag, but we can't use :nth-child in CSS due to extra hidden header elements in the list (for screenreaders)
                nthClasses = Object.keys(colsPerRow).map(function(breakpoint) {
                    return 'nth-' + ((itemIndex % colsPerRow[breakpoint]) + 1) + '@' + breakpoint;
                });
                this.className = this.className
                    .replace('-first', '')
                    .replace('-last', '')
                    .replace(/nth-\S*\b/g, '')
                    .replace(/\s{2,}/g, ' ')
                    .split(' ')
                    .concat(nthClasses)
                    .join(' ');
                transition
                    .fromTo(this, {
                        duration: 1,
                        opacity: 0.000001
                    }, {
                        opacity: 1,
                        ease: 'sine.in'
                    }, 'item');
                itemIndex++;
            })
            .attr('data-loadmoreadded', true);

        // Append the new items
        $container.append($items.children());
        $container.find('[data-eventsgrid-item].-last').removeClass('-last');
        $container.find('[data-eventsgrid-item]:last').addClass('-last');

        requestAnimationFrame(function() {
            // Update ze grid
            updateGrid(true);

            // Reset scroll, to prevent the page jumping
            window.scrollTo(0, scrollTop);

            // Play transition
            transition.play();
        });

    }

    function updateGrid(force) {
        force = force || false;

        // Don't bother if the width and/or height didn't actually change
        let elementRect = $element[0].getBoundingClientRect();

        if (!force && (elementRect.width === elementWidth && elementRect.height === elementHeight)) {
            return false;
        }

        elementWidth = elementRect.width;
        elementHeight = elementRect.height;

        // Get teasers currently in grid
        $items = $element.find(ITEM_SELECTOR);

        // Get number of columns and rows
        let cols = 0,
            rect = null,
            lastRect = null;

        $items.each(function() {
            rect = this.getBoundingClientRect();
            if (lastRect && lastRect.top !== rect.top) {
                return false;
            }
            lastRect = rect;
            cols++;
        });

        numItems = $items.length;
        numCols = cols;
        numRows = Math.ceil(numItems / numCols);

        // Re-render statuses, if need be
        if (!displayStatuses || !statusKeys.length) {
            return false;
        }

        $element.find('.' + STATUS_LABEL_CLASSNAME).remove();

        // Exit early if there's only a single item in each row
        if (numCols <= 1) {
            return false;
        }

        let itemIndex,
            item,
            $item,
            itemOffset,
            itemStatus,
            prevItem,
            prevItemStatus,
            nextItem,
            nextItemStatus,
            matchesPrevItem,
            matchesNextItem;

        let labels = [];
        let label = null;

        rowLoop:
            for (let row = 0; row < numRows; ++row) {

                prevItem = null;

                for (let col = 0; col < numCols; ++col) {
                    itemIndex = (row * numCols) + col;

                    if (itemIndex > numItems - 1) {
                        break rowLoop;
                    }

                    item = $items[itemIndex];
                    itemStatus = getItemStatus(item);

                    nextItem = col < numCols - 1 ? $items[itemIndex + 1] : null;

                    // Is the item adjacent to an item with the same status?
                    prevItemStatus = prevItem ? getItemStatus(prevItem) : null;
                    nextItemStatus = nextItem ? getItemStatus(nextItem) : null;

                    matchesPrevItem = prevItemStatus === itemStatus;
                    matchesNextItem = nextItemStatus === itemStatus;

                    if (matchesPrevItem || matchesNextItem) {
                        if (matchesPrevItem !== matchesNextItem) {
                            $item = $(item);
                            itemOffset = $item.position();

                            if (!matchesPrevItem) {
                                label = {
                                    status: {
                                        key: itemStatus,
                                        value: statuses[itemStatus]
                                    },
                                    startAt: {
                                        left: itemOffset.left,
                                        top: itemOffset.top
                                    }
                                };
                                labels.push(label);
                            } else {
                                label = labels[labels.length - 1];
                                label.endAt = {
                                    left: itemOffset.left + $item.outerWidth(),
                                    top: itemOffset.top
                                };
                            }

                            labels[labels.length - 1] = label;
                        }
                    }

                    prevItem = item;
                }
            }

        // Create the labels
        let $container = $items.parent(),
            labelClassnames = $items.find(ITEM_STATUS_SELECTOR).attr('class'),
            labelMarkup = '',
            $label = null;
            
        label = null;

        for (let i = 0; i < labels.length; ++i) {
            label = labels[i];
            $label = $('<span class="' + [STATUS_LABEL_CLASSNAME, '-' + label.status.key].join(' ') + '"><span class="' + labelClassnames + '"><span class="label">' + label.status.value + '</span></span></span>');
            $label.css({
                display: 'block',
                position: 'absolute',
                top: Math.round(label.endAt.top),
                left: Math.round(label.startAt.left),
                width: Math.round(label.endAt.left - label.startAt.left)
            });
            labelMarkup += $('<div />').append($label).html();
        }

        if (labelMarkup.length) {
            $container.append(labelMarkup);
        }
    }

    function getItemStatus(el) {
        return el.getAttribute(STATUS_ATTRIBUTE);
    }
    
    return {
        init, destroy
    };
};
