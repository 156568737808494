import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';
import LazySizes from 'lazysizes';

export default (el, props) => {
    const BP_PORTRAIT_IMAGE = 1200;

    const OVERLAY_PORTRAIT_RESIZE_WIDTH_FACTOR = 800 / 358;
    const OVERLAY_PORTRAIT_RESIZE_HEIGHT_FACTOR = 1900 / 477;
    const OVERLAY_PORTRAIT_CORNER_OFFSET_X = 226 / 800;
    const OVERLAY_PORTRAIT_CORNER_OFFSET_Y = 674 / 1900;

    const OVERLAY_LANDSCAPE_RESIZE_WIDTH_FACTOR = 1250 / 345;
    const OVERLAY_LANDSCAPE_RESIZE_HEIGHT_FACTOR = 1000 / 460;
    const OVERLAY_LANDSCAPE_CORNER_OFFSET_X = 457 / 1250;
    const OVERLAY_LANDSCAPE_CORNER_OFFSET_Y = 348 / 1000;


    let $element = $(el),
        $image = $element.find('.r21-sidebar__image'),
        $imageOverlay = $element.find('.r21-sidebar__image-overlay'),
        $imageInnerShadow = $element.find('.r21-sidebar__image-inner-shadow'),
        $maskedImageShadow = $element.find('.r21-sidebar__svg-masked-element'),

        landscapeSrcset = props.frameLandscapeSrcset,
        portraitSrcset = props.framePortraitSrcset,

        mode = null;


    const init = () => {
        mode = Viewport.breakpoint.size >= BP_PORTRAIT_IMAGE ? 'portrait' : 'landscape';

        const imageSrcset = (mode === 'portrait' ? portraitSrcset : landscapeSrcset);

        if ($imageOverlay.length) {
            $imageOverlay.attr('data-srcset', imageSrcset);
            $imageOverlay.attr('data-sizes', 'auto');
            $imageOverlay[0].addEventListener('load', frameImageLoaded, true);
            LazySizes.loader.unveil($imageOverlay[0]);
        }

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    /* --- Event listeners ---------------------------------------------------------------- */

    function onResize(e) {
        const newMode = Viewport.breakpoint.size >= BP_PORTRAIT_IMAGE ? 'portrait' : 'landscape';

        if (newMode !== mode) {
            mode = newMode;

            const imageSrcset = (mode === 'portrait' ? portraitSrcset : landscapeSrcset);

            if ($imageOverlay.length) {
                $imageOverlay.attr('data-srcset', imageSrcset);
                gsap.set($element, { opacity: 0 });
                $imageOverlay[0].addEventListener('load', frameImageLoaded, true);
                LazySizes.loader.unveil($imageOverlay[0]);
            }
        }

        sizeImages();
    }


    /* --- Private functions ------------------------------------------------------------- */

    function frameImageLoaded(e) {
        gsap.to($element, { duration: 0.3, delay: 0.5, opacity: 1 })
        e.target.removeEventListener('load', frameImageLoaded, true);
        sizeImages();
    }

    function sizeImages() {
        const imageWidth = $image.outerWidth();
        const imageHeight = $image.outerHeight();
        const imageTop = $image.position().top;
        const imageLeft = $image.position().left;

        //log.debug(imageTop, imageLeft);

        const overlayWidth = imageWidth * (mode === 'portrait' ? OVERLAY_PORTRAIT_RESIZE_WIDTH_FACTOR : OVERLAY_LANDSCAPE_RESIZE_WIDTH_FACTOR);
        const overlayHeight = imageHeight * (mode === 'portrait' ? OVERLAY_PORTRAIT_RESIZE_HEIGHT_FACTOR : OVERLAY_LANDSCAPE_RESIZE_HEIGHT_FACTOR);

        $imageOverlay.css({
            width: overlayWidth,
            height: overlayHeight,
            left: imageLeft - (overlayWidth * (mode === 'portrait' ? OVERLAY_PORTRAIT_CORNER_OFFSET_X : OVERLAY_LANDSCAPE_CORNER_OFFSET_X)),
            top: imageTop - (overlayHeight * (mode === 'portrait' ? OVERLAY_PORTRAIT_CORNER_OFFSET_Y : OVERLAY_LANDSCAPE_CORNER_OFFSET_Y))
        });

        $imageInnerShadow.css({ width: imageWidth, height: imageHeight, top: imageTop, left: imageLeft });
        $maskedImageShadow.css({ width: imageWidth, top: imageTop + (imageHeight / 2) - 10 });
    }
        

    return {
        init,
        destroy
    };
};
