/**
THIS WAS BROUGHT OVER FROM THE OLD SITE.

There are slight differences in how the tab handling work in the old
and the new framework. To ease the transition and minimize the chance
of a regression, I have brough this over as it was in the old framework.
Sorry.

- @ndre 
 */

// Dependencies
import $ from 'jquery';

// Private properties
var $body = $('body'),
    selectableSelectors = "a, input, button, select, textarea, iframe";

function resetTabbingForNode($node, recursive) {
    $node.attr('tabIndex', $node.data('_tabIndex'));
    if (recursive) {
        $node
            .find(selectableSelectors)
            .each(function() {
                resetTabbingForNode($(this));
            });
    }
}

function disableTabbingForNode($node, recursive) {
    $node
        .data('_tabIndex', $node.data('_tabIndex') || $node.attr('tabIndex') || null)
        .attr('tabIndex', -1);
    if (recursive) {
        $node
            .find(selectableSelectors)
            .each(function() {
                disableTabbingForNode($(this));
            });
    }
}

export default {
    overrideTabbing: function(selector, elementToFocus) {
        // First, reset
        this.resetTabbing();

        // Remove tabbing from body and all tabbable children
        disableTabbingForNode($body, true);

        // Setup tabbing for given selector and all tabbable children within
        resetTabbingForNode($(selector), true);

        // Set focus to element
        if (elementToFocus) {
            $(elementToFocus).focus();

        }
    },
    resetTabbing: function(elementToFocus) {

        // Reset tabbing for body and all tabbable children
        resetTabbingForNode($body, true);

        if (elementToFocus) {
            $(elementToFocus).focus();
        }
    }
};
