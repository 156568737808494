import $ from 'jquery';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../../lib/events';

export default el => {
    const LIST_SELECTOR = '[data-featureslider-list]';

    let $element = $(el);
    let $yearListButtons = $element.find('.winners-top__year-nav-list-button'),
        $yearNav = $element.find('[data-year-menu-wrapper]'),
        menuOpen = false;

    const init = () => {
        $element.addClass('-js-enabled');

        $yearListButtons.css({ top: -25, opacity: 0 });
        $yearNav.css({ height: 0 });

        $element.on('click', '[data-year-menu-toggle]', function(e) {
            e.preventDefault();
            toggleYearMenu();
        });

        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };


    /* --- Event listeners ---------------------------------------------------------------- */
    function onResize(key, data) {
        fixYearListButtonBars();
    }

    /* --- Private methods --------------------------------------------------------------- */

    function fixYearListButtonBars() {
        let lastY = -1;
        let $last = null;

        $yearListButtons.removeClass('-last-on-line');

        $yearListButtons.each(function() {
            let $item = $(this);
            let itemOffset = $item.offset().top;

            if ($last && (lastY !== itemOffset)) {
                $last.addClass('-last-on-line');
            }

            $last = $item;
            lastY = $item.offset().top;
        });
    }

    function toggleYearMenu() {
        if (menuOpen) {
            closeYearMenu();
        } else {
            openYearMenu();
        }
    }

    function openYearMenu() {
        $yearNav.css({ height: 'auto' });
        let targetHeight = $yearNav.outerHeight(true);
        $yearNav.css({ height: 0 });

        gsap.to($yearNav,  {
            duration: 0.5, height: targetHeight, ease: 'quart.inOut', onComplete: function() {
                $yearNav.css({ height: 'auto' });
            }
        });

        $yearListButtons.each(function(i) {
            let $button = $(this);

            gsap.to($button, { duration: 0.5, delay: 0.3, opacity: 1, ease: 'sine.out' });
            gsap.to($button, { duration: 0.5, delay: 0.3, top: 0, ease: 'expo.out' });
        });

        menuOpen = true;
    }

    function closeYearMenu() {
        gsap.to($yearListButtons, { duration: 0.1, opacity: 0 });
        gsap.to($yearNav, {
            duration: 0.3, height: 0, ease: 'quart.inOut', onComplete: function() {
                $yearListButtons.css({ top: -25, opacity: 0 });
            }
        });

        menuOpen = false;

    }
        
    return {
        init, destroy
    };
};
