import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default el => {
    const BP_IS_FIXED = 1200;

    let $element = $(el),
        sidebarIsFixed = true,
        menuIsOpen = false;


    const init = () => {
        $element.on('click', '.fixed-sidebar__menu-link', function(e) {
            if (Viewport.breakpoint.size >= BP_IS_FIXED) {
                e.preventDefault();
                const $link = $(this);
                openPanel($link);
            }
        });

        Dispatch.on(eventKeys.SCROLLING_LOCKED, onScrollingLocked, true);
        Dispatch.on(eventKeys.SCROLLING_RELEASED, onScrollingReleased, true);
        Dispatch.on(eventKeys.SIDEBAR_FIXED, onSidebarChanged, true);
        Dispatch.on(eventKeys.SIDEBAR_ABSOLUTE, onSidebarChanged, true);
        Dispatch.on(eventKeys.MENU_OPEN, onMenuChanged, true);
        Dispatch.on(eventKeys.MENU_CLOSE, onMenuChanged, true);
    };

    const destroy = () => {
        $element.off('click');

        Dispatch.off(eventKeys.SCROLLING_LOCKED, onScrollingLocked, true);
        Dispatch.off(eventKeys.SCROLLING_RELEASED, onScrollingReleased, true);
        Dispatch.off(eventKeys.SIDEBAR_FIXED, onSidebarChanged, true);
        Dispatch.off(eventKeys.SIDEBAR_ABSOLUTE, onSidebarChanged, true);
        Dispatch.off(eventKeys.MENU_OPEN, onMenuChanged, true);
        Dispatch.off(eventKeys.MENU_CLOSE, onMenuChanged, true);
    };

    /* --- Event listeners ---------------------------------------------------------------- */

    function onSidebarChanged(key, data) {
        if (!sidebarIsFixed && key === eventKeys.SIDEBAR_FIXED) {
            if (!menuIsOpen) {
                $element.css({ display: '' });
                gsap.killTweensOf($element);
                gsap.to($element, { duration: 0.1, opacity: 1 });
            }
            sidebarIsFixed = true;
        }

        if (sidebarIsFixed && key === eventKeys.SIDEBAR_ABSOLUTE) {
            gsap.killTweensOf($element);
            gsap.to($element, {
                duration: 0.1, opacity: 0, onComplete: function() {
                    $element.css({ display: 'none' });
                }
            });

            sidebarIsFixed = false;
        }
    }

    function onMenuChanged(key, data) {
        if (menuIsOpen && key === eventKeys.MENU_CLOSE) {
            if (sidebarIsFixed) {
                $element.css({ display: '' });
                gsap.killTweensOf($element);
                gsap.to($element, { duration: 0.1, delay: 0.6, opacity: 1 });
            }

            menuIsOpen = false;
        }

        if (!menuIsOpen && key === eventKeys.MENU_OPEN) {
            gsap.killTweensOf($element);
            gsap.to($element, {
                duration: 0.1, opacity: 0, onComplete: function() {
                    $element.css({ display: 'none' });
                }
            });

            menuIsOpen = true;
        }
    }

    function onScrollingLocked() {
        const scrollbarWidth = Config.get('scrollbarWidth') || 0;
        
        $element.css({
            paddingRight: scrollbarWidth
        });
    }

    function onScrollingReleased() {
        $element.css({
            paddingRight: ''
        });
    }
    
    /* --- Private methods --------------------------------------------------------------- */

    function openPanel($link) {
        const content = $link.data('panel-content');
        $element.find('.fixed-sidebar__menu-link').removeClass('-selected');
        $link.addClass('-selected');

        Dispatch.emit(eventKeys.SIDEBAR_CONTENT_SELECTED, { content: content });
    }

    return {
        init,
        destroy
    };
};
