import $ from '../core/Dom';

import { loadYoutube, loadVimeo } from "./async-bundles";

export class VideoController {
    constructor(cb) {
        this.cb = cb;
        this.YouTubePlayer = false;
        this.youtubeLoaded = false;
        this.VimeoConstructor = false;
        this.playerReady = false;
    }

    init($element, shouldPlay) {
        // console.log('init');

        this.autoplay = shouldPlay;

        if ($element.is('iframe')) {
            this.initEmbed($element);
        } else if ($element.is('video')) {
            this.initHtmlPlayer($element);
        }
    }

    initEmbed($element) {
        // console.log('initEmbed');

        this.isYoutube = $element.attr('src')
            .indexOf('vimeo') === -1;
        this.isHtmlPlayer = false;

        if (this.isYoutube) {
            if (!VideoController.youtubeLoaded) {
                // console.log('YouTube not loaded');

                loadYoutube(YouTubePlayer => {
                    // console.log('loadYoutube');
                    VideoController.YouTubePlayer = YouTubePlayer;
                    VideoController.youtubeLoaded = true;
                    this.createYoutubePlayer($element);
                });
            } else {
                // console.log('YouTube already loaded');
                this.createYoutubePlayer($element);
            }
        } else {
            if (!VideoController.vimeoLoaded) {
                // console.log('Vimeo not loaded');

                loadVimeo(Player => {
                    // console.log('loadVimeo');
                    VideoController.VimeoConstructor = Player.default;
                    this.createVimeoPlayer($element);
                });
            } else {
                this.createVimeoPlayer($element);
            }
        }
    }

    createYoutubePlayer($element) {
        // console.log('createYoutubePlayer');
        this.player = VideoController.YouTubePlayer($element.get(0));
        this.ytReadyListener = this.player.on('ready', this.onPlayerReady.bind(this));
        this.ytChangeListener = this.player.on('stateChange', this.onPlayerStateChange.bind(this));
    }

    createVimeoPlayer($element) {
        // console.log('createVimeoPlayer');
        this.player = new VideoController.VimeoConstructor($element.get(0));

        this.player.on('play', () => {
            this.onPlayerStateChange({ data: 1 });
        });

        this.player.on('pause', () => {
            this.onPlayerStateChange({ data: 2 });
        });

        this.onPlayerReady();
    }

    initHtmlPlayer($element) {
        // console.log('initHtmlPlayer');
    }

    onPlayerReady() {
        // console.log('onPlayerReady', this);

        this.playerReady = true;

        // if (this.autoplay) {
        //     this.play();
        // }
    }

    play() {
        // console.log('play');
        if (this.playerReady) {
            if (this.isYoutube) {
                this.player.playVideo();
            } else {
                this.player.play();
            }
        }
    }

    pause() {
        if (this.playerReady) {
            if (this.isYoutube) {
                this.player.pauseVideo();
            } else {
                this.player.pause();
            }
        }
    }

    onPlayerStateChange(e) {
        // console.log('onPlayerStateChange', this);
        this.cb(e.data);
    }

    destroy() {
        // console.log('destroy');

        if (this.isYoutube) {
            if (VideoController.youtubeLoaded) {
                this.player.off(this.ytReadyListener);
                this.player.off(this.ytChangeListener);
                this.player.destroy();
            }
        } else {
            if (this.isHtmlPlayer) {
                //do something
            } else if (VideoController.vimeoLoaded) {
                this.player.off('play');
                this.player.off('pause');
            }
        }

        this.playerReady = false;
        this.autoplay = false;
    }

    get PLAY() {
        return 1;
    }

    get PAUSE() {
        return 2;
    }
}

export default VideoController;
