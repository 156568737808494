import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default el => {

    const BP_MENU_BOTTOM = 750,
        HIDDEN_THRESHOLD = 0.35, // Percentage of viewport height
        HIDDEN_CLASS = '-hidden',
        TRANSPARENT_HEADER_BODYCLASS = '-pageheader-transparent',
        INVERTED_HEADER_BODYCLASS = '-pageheader-inverted',
        MENU_OPEN_BODYCLASS = '-pageheader-menuopen';

    var $element = $(el),
        bodyClasses = document.body.classList,
        classList = $element[0].classList,
        $wrapper = $element.find('.page-header__wrapper'),
        $bar = $element.find('.page-header__bar'),
        $barBg = $element.find('.page-header__bar-bg'),
        barHeight = 0,
        windowHeight = 0,
        lastScrollTop = null,
        menuVisible = bodyClasses.contains(MENU_OPEN_BODYCLASS),
        hidden = classList.contains(HIDDEN_CLASS),
        inverted = bodyClasses.contains(INVERTED_HEADER_BODYCLASS),
        transparent = bodyClasses.contains(TRANSPARENT_HEADER_BODYCLASS),
        modifierAttributes = null;

    const init = () => {
        $element.addClass('-js-enabled');

        $element.on('click', '.page-header__bar-menu-toggle', function(e) {
            e.preventDefault();
            toggleMenu();
        });

        $element.on('click', '.mega-menu__close-button', function(e) {
            e.preventDefault();
            toggleMenu();
        });

        $('body').on('keyup', onKeyUp);

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('scroll', onScroll);

        Dispatch.on(eventKeys.SCROLLING_LOCKED, onScrollingLocked, true);
        Dispatch.on(eventKeys.SCROLLING_RELEASED, onScrollingReleased, true);
    };

    const destroy = () => {
        $element.off('click');

        $('body').off('keyup', onKeyUp);

        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('scroll', onScroll);

        Dispatch.off(eventKeys.SCROLLING_LOCKED, onScrollingLocked, true);
        Dispatch.off(eventKeys.SCROLLING_RELEASED, onScrollingReleased, true);
    };

    /* --- Event listeners ---------------------------------------------------------------- */

    function onResize(e) {
        barHeight = $bar.outerHeight();
        windowHeight = Viewport.height;

        Dispatch.emit(eventKeys.PAGE_HEADER_RESIZE, { height: barHeight })
        updateModifiers();
    }

    function onBreakpoint(e) {
        resetMenuPosition(e.detail.current.size);
    }

    function onScroll(key, data) {
        updateModifiers();
    }

    function onKeyUp(e) {
        if (menuVisible && e.keyCode === 27) {
            hideMenu();
        }
    }

    function onScrollingLocked() {
        const scrollbarWidth = Config.get('scrollbarWidth') || 0;

        $element.children().css({
            paddingRight: scrollbarWidth
        });
    }

    function onScrollingReleased() {
        $element.children().css({
            paddingRight: ''
        });
    }

    /* --- Private methods --------------------------------------------------------------- */

    function toggleMenu() {
        if (menuVisible) {
            hideMenu();
        } else {
            showMenu();
        }
    }

    function showMenu() {
        if (menuVisible) {
            return false;
        }

        if (Viewport.breakpoint.size >= BP_MENU_BOTTOM) {
            $wrapper.css({
                top: 'auto',
                bottom: Viewport.height - $wrapper.outerHeight()
            });

            gsap.to($wrapper, { duration: 0.8, bottom: 0, ease: 'quint.out' })
        }

        Dispatch.emit(eventKeys.MENU_OPEN);

        menuVisible = true;
        bodyClasses.add(MENU_OPEN_BODYCLASS);
    }

    function hideMenu() {
        if (!menuVisible) {
            return false;
        }

        if (Viewport.breakpoint.size >= BP_MENU_BOTTOM) {
            gsap.to($wrapper, {
                duration: 0.5,
                bottom: Viewport.height - $wrapper.outerHeight(),
                ease: 'quint.out',
                onComplete: function() {
                    $wrapper.css({ top: '', bottom: '' });
                }
            })
        }
        
        Dispatch.emit(eventKeys.MENU_CLOSE);

        menuVisible = false;

        bodyClasses.remove(MENU_OPEN_BODYCLASS);
    }

    function resetMenuPosition(currentBreakpoint) {
        if (menuVisible) {
            if (currentBreakpoint >= BP_MENU_BOTTOM) {
                $wrapper.css({ top: 'auto', bottom: 0 });
            } else {
                $wrapper.css({ top: '', bottom: '' });
            }
        } else {
            $wrapper.css({ top: '', bottom: '' });
        }
    }

    function updateModifiers(scrollTop) {
        if (!menuVisible) {
            scrollTop = scrollTop !== undefined ? scrollTop : Viewport.scrollTop;

            if (scrollTop < 0) {
                scrollTop = 0;
            }

            if (lastScrollTop !== null) {
                const scrollDiff = Math.abs(scrollTop - lastScrollTop);
                
                if (scrollDiff > 15) {
                    const scrollPerc = Math.abs(scrollTop / windowHeight);
                    const scrollDirection = scrollTop >= lastScrollTop ? 'down' : 'up';
                    setHidden(scrollPerc > HIDDEN_THRESHOLD && scrollDirection === 'down');
                    lastScrollTop = scrollTop;
                }
            } else {
                lastScrollTop = scrollTop;
                setHidden(false);
            }

            var $modEls = $('[data-pageheadermod]'),
                numModEls = $modEls.length;

            if (!numModEls) {
                return false;
            }

            var els = $modEls.get().reverse(),
                el;
            for (var i = 0; i < numModEls; ++i) {
                el = els[i];
                if ((el.getBoundingClientRect().top - barHeight) <= 0) {
                    break;
                }
            }

            if (el) {
                var attributes = el.getAttribute('data-pageheadermod') || '{}';
                if (attributes !== modifierAttributes) {
                    var modifiers = JSON.parse(attributes);
                    setTransparent(modifiers.transparent || false);
                }
                modifierAttributes = attributes;
            }
        }
    }

    function setHidden(doHide) {
        doHide = doHide !== undefined ? doHide : false;
        if (doHide !== hidden) {
            classList.toggle(HIDDEN_CLASS);
        }
        hidden = doHide;
    }

    /**
     * Sets or removes the `-inverted` modifier
     * @param doInvert
     */
    function setInverted(doInvert) {
        doInvert = doInvert !== undefined ? doInvert : false;
        if (doInvert !== inverted) {
            bodyClasses.toggle(INVERTED_HEADER_BODYCLASS);
        }
        inverted = doInvert;
    }

    /**
     * Sets or removes the `-transparent` modifier
     * @param doTransparent
     */
    function setTransparent(doTransparent) {
        doTransparent = doTransparent !== undefined ? doTransparent : false;
        if (doTransparent !== transparent) {
            bodyClasses.toggle(TRANSPARENT_HEADER_BODYCLASS);
        }
        transparent = doTransparent;
    }

    return {
        init,
        destroy
    };

};
