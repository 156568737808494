import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Packer from '../lib/packer';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const BP_START_PACKING = 750;

    let $element = $(el);
    let $items = null,
        $grid = null,
        startPackingBreakpoint = 0;

    const init = () => {
        if (props.gridSelector && props.gridSelector !== '') {
            $grid = $element.find(props.gridSelector);
        } else {
            $grid = $element;
        }

        if (props.startAtBP && props.startAtBP > 0) {
            startPackingBreakpoint = props.startAtBP;
        }

        if (props.itemSelector && props.itemSelector !== '') {
            $items = $element.find(props.itemSelector);

            if ($items.length > 0) {
                $element.addClass('-js-enabled');


                $items.find('img').on('load', function() {
                    pack();
                }).addClass('lazyload');
            }
        }

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
        
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
    };
    
/* --- Event listeners ---------------------------------------------------------------- */
        function onResize(e) {
            pack();
        }
        
        function onBreakpoint(e) {
            
            if (e.detail.old.size < startPackingBreakpoint && e.detail.current.size >= startPackingBreakpoint) {
                pack();
            }
            
            if (e.detail.old.size >= startPackingBreakpoint && e.detail.current.size < startPackingBreakpoint) {
                $items.css({ position: '', left: '', top: '' });
                $grid.css({ height: '' });
            }
        }

        /* --- Private methods --------------------------------------------------------------- */

        function pack() {
            if (Viewport.breakpoint.size >= startPackingBreakpoint) {
                let itemRectangles = getRectangles();

                let gridHeight = 0;
                let packer = new Packer(Math.ceil($grid.outerWidth()), 99999);

                packer.addAll(itemRectangles);

                for (let n = 0; n < packer.positioned.length; n++) {
                    let block = packer.positioned[n];

                    block.datum.$item.css({position: 'absolute', left: block.x, top: block.y});

                    if (block.y + block.height > gridHeight) {
                        gridHeight = block.y + block.height;
                    }

                }

                $grid.css({height: gridHeight});
            }

        }

        function getRectangles() {
            let arr = [];
            let $item = null;

            $items.each(function() {
                $item = $(this);
                arr.push({$item: $item, width: Math.floor($item.outerWidth(true)), height: Math.floor($item.outerHeight(true))});
            });

            return arr;
        }    

    return {
        init, destroy
    };
};
