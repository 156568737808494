import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const LIST_SELECTOR = '[data-featureslider-list]';

    let $element = $(el);
    let nestingModifier = props ? props.nestingModifier || null : null;

    let $sidebar = $element.find('[data-article-sidebar]');
    let hasSidebar = !!$sidebar.length;

    let $blocks = $element.find('[data-article-blocks]').children();
    let hasBlocks = $blocks.length;

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    /* --- Event listeners ---------------------------------------------------------------- */
    function onResize(e) {
        updateElementHeight();
        updateNestedBlocks();
    }

    /* --- Private methods --------------------------------------------------------------- */

    function updateNestedBlocks() {
        if (!hasBlocks || !hasSidebar || !nestingModifier) {
            $blocks.removeClass(nestingModifier);
            return false;
        }

        $blocks.addClass(nestingModifier);

        let sidebarVerticalBound = $sidebar.offset().top + $sidebar.outerHeight();
        let $block;

        $blocks.each(function() {
            $block = $(this);
            if ($block.offset().top > sidebarVerticalBound) {
                $block.removeClass(nestingModifier);
            }
        });
    }

    function updateElementHeight() {
        if (!hasSidebar) {
            return false;
        }

        // The content needs to be at least as tall as the sidebar
        $element.css({
            height: ''
        });

        let sidebarHeight = $sidebar.offset().top + $sidebar.outerHeight();
        let elementHeight = $element.offset().top + $element.outerHeight();
        let missingPixels = sidebarHeight - elementHeight;

        if (missingPixels > 0) {
            $element.css({
                height: elementHeight + missingPixels
            });
        }

    }

    return {
        init, destroy
    };
};
