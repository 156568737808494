import $ from 'jquery';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../../lib/events';

export default el => {
    let $element = $(el);
    let $nav = $element.find('[data-subcategory-menu-wrapper]'),
        $close = $element.find('[data-subcategory-menu-close]'),
        menuOpen = false;

    const init = () => {
        $element.addClass('-js-enabled');
        $nav.css({ height: 0 });
        $close.css({ display: 'block', bottom: -50 });

        $element.on('click', '[data-subcategory-menu-toggle]', function(e) {
            e.preventDefault();
            toggleMenu();
        });
    };

    const destroy = () => {
        $element.off('click');
    };

    function toggleMenu() {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    }

    function openMenu() {
        $nav.css({ height: 'auto' });
        let targetHeight = $nav.outerHeight(true);
        $nav.css({ height: 0 });

        gsap.to($nav, {
            duration: 0.5, height: targetHeight, ease: 'quart.inOut', onComplete: function() {
                $nav.css({ height: 'auto' });
            }
        });

        gsap.to($close,  { duration: 0.4, delay: 0.4, bottom: 0, ease: 'quint.out' });

        menuOpen = true;
    }

    function closeMenu() {
        gsap.to($nav, {
            duration: 0.5, delay: 0.1, height: 0, ease:'quart.inOut', onComplete: function() {
            }
        });

        gsap.to($close, { duration: 0.2, bottom: -50, ease: 'sine.in' });

        menuOpen = false;
    }

    return {
        init, destroy
    };
};
