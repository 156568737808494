import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';

export default el => {
    const BP_IS_FIXED = 1200;

    let $element = $(el),
        $topArea = $('.frontpage__top'),
        $column = $element.find('.fixed-sidebar__column'),
        $contentSidebars = $element.find('.fixed-sidebar__column-content'),

        contentLoaded = {
            r21: true,
            calendar: false,
            feed: false
        },

        isFixed = true,
        maxColumnOffset = 0;

    const init = () => {
        if (Viewport.breakpoint.size >= BP_IS_FIXED) {
            getMaxColumnOffset();
            positionColumn();
        }
        
        if (Viewport.breakpoint.size >= BP_IS_FIXED) {
            getMaxColumnOffset();
            positionColumn();
        }

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('scroll', onScroll);

        Dispatch.on(eventKeys.SCROLLING_LOCKED, onScrollingLocked, true);
        Dispatch.on(eventKeys.SCROLLING_RELEASED, onScrollingReleased, true);
        Dispatch.on(eventKeys.SIDEBAR_CONTENT_SELECTED, onContentSelected, true);
        Dispatch.on(eventKeys.VIDEO_MODULE_RESIZED, onVideoModuleResized, true);
    };

    const destroy = () => {
        $element.off('click');

        $('body').off('keyup', onKeyUp);

        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('scroll', onScroll);

        Dispatch.off(eventKeys.SCROLLING_LOCKED, onScrollingLocked, true);
        Dispatch.off(eventKeys.SCROLLING_RELEASED, onScrollingReleased, true);
    };

    /* --- Event listeners ---------------------------------------------------------------- */
    function onVideoModuleResized(key, data) {
        if (Viewport.breakpoint.size >= BP_IS_FIXED) {
            getMaxColumnOffset();
            positionColumn();
        }
    }

    function onScroll(e) {
        if (Viewport.breakpoint.size >= BP_IS_FIXED) {
            positionColumn();
        }
    }

    function onResize(e) {
        if (Viewport.breakpoint.size >= BP_IS_FIXED) {
            getMaxColumnOffset();


            if (!isFixed) {
                $column.css({ top: maxColumnOffset });
            }

            positionColumn();
        }
    }

    function onBreakpoint(e) {
        if (e.detail.old.size < BP_IS_FIXED && e.detail.current.size >= BP_IS_FIXED) {
            getMaxColumnOffset();
            positionColumn();
        }
        if (e.detail.old.size >= BP_IS_FIXED && e.detail.current.size < BP_IS_FIXED) {
            resetColumn();
        }
    }

    function onContentSelected(key, data) {
        loadContent(data.content);
    }

    function onScrollingLocked() {
        const scrollbarWidth = Config.get('scrollbarWidth') || 0;
        
        $column.css({
            paddingRight: scrollbarWidth
        });
    }

    function onScrollingReleased() {
        $column.css({
            paddingRight: ''
        });
    }

    /* --- Private methods --------------------------------------------------------------- */

    function positionColumn(scrollTop) {
        scrollTop = (scrollTop === undefined ? Viewport.scrollTop : scrollTop);

        if (isFixed && (scrollTop > maxColumnOffset)) {
            $column.css({ position: 'absolute', top: maxColumnOffset });
            Dispatch.emit(eventKeys.SIDEBAR_ABSOLUTE)
            isFixed = false;
        }

        if (!isFixed && (scrollTop < maxColumnOffset)) {
            $column.css({ position: '', top: '' });
            Dispatch.emit(eventKeys.SIDEBAR_FIXED)
            isFixed = true;
        }
    }

    function resetColumn() {
        $column.css({ position: '', top: '' });
        Dispatch.emit(eventKeys.SIDEBAR_FIXED)
    }

    function getMaxColumnOffset() {
        maxColumnOffset = $topArea.outerHeight() - Viewport.height + 105 - 40;
    }

    function loadContent(content) {
        const $selectedSidebar = $contentSidebars.filter('.-' + content);

        $contentSidebars.removeClass('-is-open');
        $selectedSidebar.addClass('-is-open');

        if (!contentLoaded[content]) {
            const urls = Config.get('urls').sidebarContent;
            const contentUrl = urls[content];

            $selectedSidebar.load(contentUrl);

            contentLoaded[content] = true;
        }
    }

    return {
        init,
        destroy
    };
};

