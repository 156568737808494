import $ from 'jquery';
import Config from '../core/Config';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import * as eventKeys from '../lib/events';
import VideoController from '../lib/VideoController';

export default (el, props) => {
    const LIST_SELECTOR = '[data-featureslider-list]';

    let $element = $(el);
    let $embed = null;
    let player = null;
    
    let controller;
    let videoInited = false;

    const init = () => {
        $element.on('click', '[data-trigger]', onClick);
        controller = new VideoController(onStateChange);
    };

    const destroy = () => {
        $element.off('click');
        destroyPlayer();
    };

    /* --- Event handlers ---------------------------------------------------------------- */
    function onClick(e) {
        e.preventDefault();
        createPlayer();
    }

    function onVideoEnded() {
        destroyPlayer();
    }
    
    function onStateChange(e) {

    }

    /* --- Private methods --------------------------------------------------------------- */
    function createPlayer() {
        if ($embed) {
            destroyPlayer();
        }
        
        if (!videoInited) {
            videoInited = true;
            $element.html(props.embedCode);
            controller.init($element.find('iframe'), true);
            play();
        } else {
            controller.play();
            play();
        }
    }

    const play = () => {
        
    };

    function destroyPlayer() {
        if (!$embed) {
            return false;
        }
        if (player) {
            $embed.addClass('-hidden');
            player.pause();
            player.off('ended');
            player
                .unload()
                .then(function() {
                    $embed.remove();
                    $embed = null;
                })
                .catch(function(error) {
                });
            player = null;
        } else {
            $embed.remove();
            $embed = null;
        }
    }

    return {
        init, destroy
    };
};
